import { createResourceSlice } from '../redux/resource/createResourceSlice';
import { JOURNAL_RESOURCE } from '../api/resources';

const journalSlice = createResourceSlice({
    resource: JOURNAL_RESOURCE,
    byKey: '',
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
    indexJournals,
    showJournal,
    storeJournal,
    updateJournal,
    archiveJournal,
    restoreJournal,
    destroyJournal,
    suggestJournals,
} = journalSlice.actions;

export const {
    selectJournalById,
    makeJournalsByIdsSelector,
    selectAllJournals,
    selectJournalsLoading,
    selectJournalsInitialized,
    selectJournalLoading,
    selectJournalInitialized,
    selectJournalIdsBy,
    makeJournalsBySelector,
    selectJournalLoadingBy,
    selectJournalInitializedBy,
} = journalSlice.selectors;

export const { useJournals, useJournal, useJournalsBy } = journalSlice.hooks;

export default journalSlice.reducer;
