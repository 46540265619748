import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useResourceList } from '../lists/hooks';
import { getRemoteAggregationConfig } from './remote';
import { aggregateList } from '../lists/actions';
import { useCallbackFunc } from '../hooks';

/**
 *
 * @param listId
 * @param resource
 * @param columns
 *          array of column definition objects.
 *          requires a `aggregationConfig` property.
 *          aggregationConfig: {
 *              type: <AGGREGATION_TYPE>,
 *              fields: array of fields included or fallback, optional
 *              alias: string field name only used for aggregated values
 *              convertString: convert to displayed string when format should be different from non-aggregated values
 *          },
 * @param fetchParams
 * @param onCheckLoad function to prevent load when no sufficient values
 * @return {[]|*}
 */

export const useAggregations = ({ listId, resource, columns, fetchParams = {}, onCheckLoad }) => {
    const dispatch = useDispatch();
    const [params, setParams] = useState(fetchParams);
    const lastParams = useRef();

    const stableCheck = useCallbackFunc(() => {
        return true;
    });

    const stableCompare = useCallbackFunc(() => 1);

    const { initialized, loading, fullSelector } = useResourceList({
        listId,
        resource,
        criteria: {
            check: stableCheck,
            compare: stableCompare,
        },
    });

    const list = useSelector(fullSelector);

    const { aggregationParams, aggregationConfig } = useMemo(
        () => getRemoteAggregationConfig(columns),
        [columns]
    );

    const { total, byGrouped } = useMemo(() => {
        return {
            total: list['aggregations.total'],
            byGrouped: null,
        };
    }, [list, aggregationConfig]);

    const handleParams = newParams => {
        setParams(currentParams => ({
            ...currentParams,
            ...newParams,
        }));
    };

    useEffect(() => {
        if (!loading) {
            const { with_shallow, limit, ...includedParams } = params; // Generalized param cleanup function?
            const nextParams = { aggs: aggregationParams, ...includedParams };

            if (!onCheckLoad || onCheckLoad(nextParams)) {
                const comparableParams = [
                    listId,
                    ...Object.values(nextParams),
                    ...Object.keys(nextParams),
                ].join();

                if (comparableParams !== lastParams.current) {
                    lastParams.current = comparableParams;
                    dispatch(aggregateList(listId, nextParams));
                }
            }
        }
    }, [listId, aggregationParams, params, loading]);

    return {
        initialized,
        loading,
        total,
        byGrouped,
        handleParams,
    };
};
