import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import {
    AgendaView,
    MonthView,
    WeekView,
    DayView,
    AllDayPanel,
} from '@devexpress/dx-react-scheduler-material-ui';
import { alpha, styled } from '@mui/material';
import { useAppointmentCalendar } from './AppointmentCalendarContext';
import {
    appointmentAllDayMinHeight,
    appointmentDefaultHeight,
    appointmentMinHeight,
    buildStripedBackground,
    checkOverlap,
} from '../../modules/calendar/utils';
import isSameDay from 'date-fns/isSameDay';
import { getDay } from 'date-fns';

const schedulerHeaderHeight = 30 + 64; // static value (Weekday + Navigation)

const PALETTE = {
    base: '#efefef',
    stripes: '#ffdddd',
    baseHover: '#dddddd',
};

const PREFIX = 'DayCell';

const classes = {
    todayCell: `${PREFIX}-todayCell`,
    weekendCell: `${PREFIX}-weekendCell`,
    deniedCell: `${PREFIX}-deniedCell`,
};

const styles = ({ theme }) => ({
    [`&.${classes.todayCell}`]: {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.14),
        },
        '&:focus': {
            backgroundColor: alpha(theme.palette.primary.main, 0.16),
        },
    },
    [`&.${classes.weekendCell}`]: {
        backgroundColor: alpha(theme.palette.action.disabledBackground, 0.04),
        '&:hover': {
            backgroundColor: alpha(theme.palette.action.disabledBackground, 0.08),
        },
        '&:focus': {
            backgroundColor: alpha(theme.palette.action.disabledBackground, 0.1),
        },
    },
    [`&.${classes.deniedCell}`]: {
        background: buildStripedBackground(PALETTE.base, PALETTE.stripes),
        backgroundSize: '8.00px 8.00px',

        '&:hover': {
            background: buildStripedBackground(PALETTE.baseHover, PALETTE.stripes),
            backgroundSize: '8.00px 8.00px',
        },
    },
});

export const CALENDAR_VIEWS = {
    AGENDA: 'agenda',
    MONTH: 'month',
    WEEK: 'week',
    DAY: 'day',
    ALLDAY: 'allday',
};

const CALENDAR_VIEW_PROPS = {
    [CALENDAR_VIEWS.AGENDA]: {
        CellComponent: styled(AgendaView.TimeTableCell)(styles),
    },
    [CALENDAR_VIEWS.MONTH]: {
        CellComponent: styled(MonthView.TimeTableCell)(styles),
    },
    [CALENDAR_VIEWS.WEEK]: {
        CellComponent: styled(WeekView.TimeTableCell)(styles),
    },
    [CALENDAR_VIEWS.DAY]: {
        CellComponent: styled(DayView.TimeTableCell)(styles),
    },
    [CALENDAR_VIEWS.ALLDAY]: {
        CellComponent: styled(AllDayPanel.Cell)(styles),
    },
};

const CalendarCell = ({
    calendarView,
    schedulerHeight,
    groupingInfo,
    startDate,
    endDate,
    onDoubleClick,
    ...other
}) => {
    const { maxDailyAppointmentsPerRow, deniedPeriods } = useAppointmentCalendar();
    const { CellComponent } = CALENDAR_VIEW_PROPS[calendarView];

    const isToday = useMemo(() => isSameDay(startDate, new Date()), [startDate]);
    const isWeekend = useMemo(
        () => getDay(startDate) === 0 || getDay(startDate) === 6,
        [startDate]
    );
    const isDenied = useMemo(
        () =>
            checkOverlap(
                startDate,
                endDate,
                deniedPeriods,
                Array.isArray(groupingInfo) && groupingInfo.length > 0 && groupingInfo[0].id,
                true
            ),
        [deniedPeriods, groupingInfo]
    );

    const className = useMemo(() => {
        if (isDenied) {
            return classes.deniedCell;
        }

        if (isToday) {
            return classes.todayCell;
        }

        if (isWeekend) {
            return classes.weekendCell;
        }
    }, [classes, isDenied, isWeekend, isToday]);

    const extraProps = useMemo(() => {
        if (calendarView === CALENDAR_VIEWS.MONTH && schedulerHeight) {
            return {
                style: {
                    height: `${(schedulerHeight - schedulerHeaderHeight) / 6}px`,
                },
            };
        }

        if (calendarView === CALENDAR_VIEWS.ALLDAY) {
            const appointmentMaxCount = maxDailyAppointmentsPerRow[CALENDAR_VIEWS.ALLDAY];
            const isLarge = appointmentMaxCount > 1;

            return {
                style: {
                    height: `${
                        isLarge
                            ? appointmentAllDayMinHeight * appointmentMaxCount
                            : appointmentDefaultHeight * 0.85
                    }px`,
                },
            };
        }

        if (
            calendarView === CALENDAR_VIEWS.AGENDA ||
            (calendarView === CALENDAR_VIEWS.GROUPING && maxDailyAppointmentsPerRow)
        ) {
            const resourceFlatKey = groupingInfo[0].id;
            const appointmentMaxCount = maxDailyAppointmentsPerRow[resourceFlatKey];
            const isLarge = appointmentMaxCount > 1;

            return {
                style: {
                    height: `${
                        isLarge
                            ? appointmentMinHeight * appointmentMaxCount
                            : appointmentDefaultHeight
                    }px`,
                },
            };
        }

        return {};
    }, [calendarView, schedulerHeight, maxDailyAppointmentsPerRow]);

    const handleDoubleClick = useMemo(
        () => (!isDenied && onDoubleClick) || (() => null),
        [isDenied, onDoubleClick]
    );

    return (
        <CellComponent
            startDate={startDate}
            endDate={endDate}
            groupingInfo={groupingInfo}
            className={className}
            onDoubleClick={handleDoubleClick}
            {...other}
            {...extraProps}
        />
    );
};

CalendarCell.propTypes = {
    calendarView: PropTypes.oneOf(Object.values(CALENDAR_VIEWS)).isRequired,
    schedulerHeight: PropTypes.number,
};

CalendarCell.defaultProps = {
    schedulerHeight: null,
};

//export default CalendarCell;

export const CalendarAgendaCell = props => (
    <CalendarCell {...props} calendarView={CALENDAR_VIEWS.AGENDA} />
);
export const CalendarMonthCell = props => (
    <CalendarCell {...props} calendarView={CALENDAR_VIEWS.MONTH} />
);
export const CalendarWeekCell = props => (
    <CalendarCell {...props} calendarView={CALENDAR_VIEWS.WEEK} />
);
export const CalendarDayCell = props => (
    <CalendarCell {...props} calendarView={CALENDAR_VIEWS.DAY} />
);
export const CalendarAllDayCell = props => (
    <CalendarCell {...props} calendarView={CALENDAR_VIEWS.ALLDAY} />
);
