import React, { useCallback, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useField, useFormikContext } from 'formik';

import { useContextualCan } from '../../modules/abilities/hooks';
import { UPDATE } from '../../modules/abilities/actions';
import { EMPLOYEE_RESOURCE, RESOURCE_RESOURCE } from '../../modules/api/resources';
import { formatFlatResourceKey } from '../../modules/resourceGroup/utils';
import ResourceGroupConfigItem from './ResourceGroupConfigItem';
import FormPopup from '../popups/FormPopup';
import EmployeeAutocomplete from '../form/formik/autocomplete/EmployeeAutocomplete';
import ResourceAutocomplete from '../form/formik/autocomplete/ResourceAutocomplete';
import IconButton from '../form/base/IconButton';
import ResourceGroupIcon from './ResourceGroupIcon';
import ItemStack from '../icons/ItemStack';

const ResourceGroupPicker = ({
    name,
    originalName,
    groupKey,
    groupConfig,
    resourceConfig: originalResourceConfig,
}) => {
    const { t } = useTranslation();
    const formFieldKey = `${name}.${groupKey}`;
    const [{ value }, , { setValue }] = useField(formFieldKey);
    const { values } = useFormikContext();
    const canDo = useContextualCan(UPDATE, `${originalName || name}.${groupKey}`);
    const groupName = groupConfig?.label;

    const handleAdd = ({ target }) => {
        const cleanValue = value || [];

        if (target?.id) {
            setValue(groupConfig?.allowMultiple ? [...cleanValue, target.id] : target.id);
        }
    };

    const handleRemove = id => {
        setValue(groupConfig?.allowMultiple ? value.filter(item => item !== id) : null);
    };

    const pickedEntityIdsByResource = useMemo(() => {
        const resourceGroupValues = values[name];

        if (resourceGroupValues) {
            const resourceConfig = originalResourceConfig || [];
            const groupKeysByResource = resourceConfig.reduce((carry, group) => {
                if (group?.type === groupConfig.type) {
                    return [...carry, group.group];
                }

                return carry;
            }, []);

            return Object.entries(resourceGroupValues).reduce((carry, [groupKey, groupIds]) => {
                if (groupKeysByResource.includes(groupKey)) {
                    return [...carry, ...(Array.isArray(groupIds) ? groupIds : [groupIds])];
                }
                return carry;
            }, []);
        }
        return [];
    }, [values, name, originalResourceConfig, groupConfig]);

    const getOptionDisabled = useCallback(
        option =>
            option.type === groupConfig.type && pickedEntityIdsByResource.includes(option?.id),
        [groupConfig, pickedEntityIdsByResource]
    );

    const baseResources = useMemo(() => {
        const cleanValue = value || [];
        const ids = Array.isArray(cleanValue) ? cleanValue : [cleanValue];

        return ids.map(id => {
            if ([EMPLOYEE_RESOURCE, RESOURCE_RESOURCE].includes(groupConfig.api)) {
                const flatKey = formatFlatResourceKey(id, groupConfig.api).key;

                return {
                    key: `resourceGroupPicker.${groupKey}.${flatKey}`,
                    component: (
                        <ResourceGroupConfigItem
                            key={`resourceGroupConfigItem.${groupKey}.${flatKey}`}
                            flatKey={flatKey}
                            groupConfig={groupConfig}
                            style={{
                                marginRight: 7,
                            }}
                            onRemove={canDo ? () => handleRemove(id) : undefined}
                            autoLoad
                        />
                    ),
                };
            }
        });
    }, [value, groupConfig]);

    const showAdd = groupConfig?.allowMultiple || baseResources?.length === 0;

    const items = useMemo(
        () => [
            ...(baseResources || []),
            ...(showAdd
                ? [
                      {
                          key: `addToGroup.${groupKey}`,
                          component: (
                              <Box
                                  style={{
                                      minHeight: 40,
                                      minWidth: 40,
                                      backgroundColor: '#fff',
                                      borderRadius: 20,
                                  }}
                              >
                                  <FormPopup
                                      popupId={groupKey}
                                      initialValues={{ target: null }}
                                      FormInputs={
                                          <>
                                              {groupConfig?.api === EMPLOYEE_RESOURCE ? (
                                                  <EmployeeAutocomplete
                                                      name="target"
                                                      fetchParams={groupConfig?.params}
                                                      autofocus
                                                      getOptionDisabled={getOptionDisabled}
                                                  />
                                              ) : null}
                                              {groupConfig?.api === RESOURCE_RESOURCE ? (
                                                  <ResourceAutocomplete
                                                      name="target"
                                                      fetchParams={groupConfig?.params}
                                                      autofocus
                                                      getOptionDisabled={getOptionDisabled}
                                                  />
                                              ) : null}
                                          </>
                                      }
                                      onSubmit={handleAdd}
                                      disabled={!canDo}
                                      autoSubmit
                                  >
                                      <IconButton
                                          label={`${groupName} ${t(
                                              'components.ResourceGroupConfig.addResource'
                                          )}`}
                                          disabled={!canDo}
                                      >
                                          <ResourceGroupIcon groupConfig={groupConfig} add />
                                      </IconButton>
                                  </FormPopup>
                              </Box>
                          ),
                      },
                  ]
                : []),
        ],
        [baseResources, showAdd, groupKey, groupConfig, groupName, getOptionDisabled]
    );

    return (
        <Grid item mr={2} mb={2} key={formFieldKey}>
            <Grid container direction="column">
                <Grid item mb={1}>
                    <Typography color="textSecondary" fontSize={10}>
                        {groupName}
                    </Typography>
                </Grid>
                <Grid item>
                    <ItemStack items={items} />
                </Grid>
            </Grid>
        </Grid>
    );
};

ResourceGroupPicker.propTypes = {
    name: PropTypes.string.isRequired,
    originalName: PropTypes.string,
    groupKey: PropTypes.string.isRequired,
    groupConfig: PropTypes.shape({}).isRequired,
    resourceConfig: PropTypes.arrayOf(PropTypes.shape({})),
};

ResourceGroupPicker.defaultProps = {
    originalName: null,
    resourceConfig: null,
};

export default ResourceGroupPicker;
