import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';

const ITEM_STACK_CONFIG = {
    overlapFactor: 0.6,
    expandedFactor: 0.4,
};

const ItemStack = ({ items, itemSize, wrap }) => {
    const [improvedWidth, setImprovedWidth] = useState(null);

    useEffect(() => {
        const expandRange = ITEM_STACK_CONFIG.overlapFactor - ITEM_STACK_CONFIG.expandedFactor;
        const visualItemSize = itemSize * ITEM_STACK_CONFIG.overlapFactor;

        setImprovedWidth(visualItemSize * items.length + expandRange);
    }, [items, itemSize]);

    return (
        <Box
            style={{
                ...(improvedWidth ? { width: improvedWidth } : {}),
            }}
        >
            <Grid
                container
                style={{
                    transition: 'margin .2s',
                    marginLeft: Math.ceil((itemSize + itemSize / 4) / 2),
                }}
                wrap={wrap ? 'wrap' : 'nowrap'}
            >
                {items.map(({ key, component }, index) =>
                    key && component ? (
                        <Grid
                            item
                            key={key}
                            sx={{
                                zIndex: index,
                                minWidth: itemSize,
                                minHeight: itemSize,
                                transition: 'margin .2s',
                                marginLeft: `${-Math.ceil(
                                    itemSize * ITEM_STACK_CONFIG.overlapFactor
                                )}px`,
                                '&:hover': {
                                    marginRight: `${Math.ceil(
                                        itemSize * ITEM_STACK_CONFIG.expandedFactor
                                    )}px`,
                                },
                            }}
                        >
                            {component}
                        </Grid>
                    ) : null
                )}
            </Grid>
        </Box>
    );
};

ItemStack.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            component: PropTypes.elementType.isRequired,
        })
    ),
    itemSize: PropTypes.number,
    wrap: PropTypes.bool,
};

ItemStack.defaultProps = {
    items: [],
    itemSize: 44,
    wrap: false,
};

export default ItemStack;
