import TextInput from '../../components/form/formik/TextInput';
import Date from '../../components/utils/Date';
import React from 'react';
import { Box, Checkbox as CheckboxBase, Icon } from '@mui/material';
import DateInput from '../../components/form/formik/DateInput';
import DurationInput from '../../components/form/formik/DurationInput';
import UserAutocomplete from '../../components/form/formik/autocomplete/UserAutocomplete';
import Duration from '../../components/utils/Duration';
import Checkbox from '../../components/form/formik/Checkbox';
import { dateToString, exportDate, formatDurationText } from '../datetime/utils';
import DateTimeToggleInput from '../../components/form/formik/DateTimeToggleInput';
import NumberInput from '../../components/form/formik/NumberInput';
import MoneyInput, { formatMoney } from '../../components/form/formik/MoneyInput';
import Avatar from '../../components/icons/Avatar';
import MultilineSubmit from '../../components/form/formik/MultilineSubmit';
import IconSelect from '../../components/form/formik/IconSelect';
import BaseAvatar from '../../components/icons/BaseAvatar';
import Color from '../../components/utils/Color';
import ColorPicker from '../../components/form/formik/ColorPicker';
import JsonInput from '../../components/form/formik/JsonInput';

export const COLUMN_TYPES = {
    TEXT: 'text',
    TEXT_MULTI: 'text_multi',
    DATE: 'date',
    DATETIME_OPTIONAL: 'datetime_opt',
    DURATION: 'duration',
    NUMBER: 'number',
    DIGITS: 'digits',
    MONEY: 'money',
    CHECKBOX_DATE: 'check_date',
    CHECKBOX_BOOL: 'check_bool',
    USER: 'user',
    ICON: 'icon',
    AVATAR_ICON: 'avatar_icon',
    COLOR: 'color',
    JSON: 'json',
};

export const getColumnName = column => {
    return column.valueKey || column.accessorKey || column.id;
};

export const aproximateColumnNamePostfix = (name, postfix) => {
    const nameChunks = name.split('_');
    const baseName = nameChunks.slice(0, nameChunks.length - 1).join('_');
    return `${baseName}_${postfix}`;
};

export const COLUMN_TYPE_CONFIG = {
    [COLUMN_TYPES.TEXT]: {
        Edit: ({ column }) => {
            const columnProps = column.columnDef;
            const name = getColumnName(columnProps);

            return <TextInput name={name} label={columnProps.header} size="small" fullWidth />;
        },
    },
    [COLUMN_TYPES.TEXT_MULTI]: {
        Edit: ({ column }) => {
            const columnProps = column.columnDef;
            const name = getColumnName(columnProps);

            return (
                <MultilineSubmit>
                    <TextInput
                        name={name}
                        label={columnProps.header}
                        size="small"
                        minRows={1}
                        multiline
                        fullWidth
                    />
                </MultilineSubmit>
            );
        },
    },
    [COLUMN_TYPES.DATE]: {
        Cell: ({ renderedCellValue }) => <Date date={renderedCellValue} />,
        Edit: ({ column }) => {
            const columnProps = column.columnDef;
            const name = getColumnName(columnProps);

            return <DateInput name={name} label={columnProps.header} size="small" fullWidth />;
        },
        convertString: dateToString,
        forcePrintString: true,
    },
    [COLUMN_TYPES.DATETIME_OPTIONAL]: {
        Cell: ({ renderedCellValue, column, row }) => {
            const columnProps = column.columnDef;
            const name = getColumnName(columnProps);
            const hasTimeName = aproximateColumnNamePostfix(name, 'has_time');

            return <Date date={renderedCellValue} onlyDate={row.original[hasTimeName] !== 1} />;
        },
        Edit: ({ column }) => {
            const columnProps = column.columnDef;
            const name = getColumnName(columnProps);
            const hasTimeName = aproximateColumnNamePostfix(name, 'has_time');

            return (
                <DateTimeToggleInput
                    name={name}
                    hasTimeName={hasTimeName}
                    label={columnProps.header}
                />
            );
        },
        convertString: dateToString,
        forcePrintString: true,
    },
    [COLUMN_TYPES.DURATION]: {
        Cell: ({ renderedCellValue }) =>
            renderedCellValue ? <Duration durationValue={renderedCellValue} /> : null,
        Edit: ({ column }) => {
            const columnProps = column.columnDef;
            const name = getColumnName(columnProps);

            return <DurationInput name={name} label={columnProps.header} size="small" fullWidth />;
        },
        convertString: formatDurationText,
        forcePrintString: true,
        forcePrintStyle: {
            textAlign: 'right',
        },
    },
    [COLUMN_TYPES.NUMBER]: {
        Edit: ({ column }) => {
            const columnProps = column.columnDef;
            const name = getColumnName(columnProps);

            return <NumberInput name={name} label={columnProps.header} size="small" fullWidth />;
        },
        convertString: value =>
            typeof value === 'number' ? Math.round(value) : value && Number.parseInt(value),
        forcePrintString: true,
        forcePrintStyle: {
            textAlign: 'right',
        },
    },
    [COLUMN_TYPES.DIGITS]: {
        Edit: ({ column }) => {
            const columnProps = column.columnDef;
            const name = getColumnName(columnProps);

            return (
                <NumberInput
                    name={name}
                    label={columnProps.header}
                    digits={1}
                    size="small"
                    fullWidth
                />
            );
        },
        convertString: value =>
            (typeof value === 'number' ? value : value && Number.parseFloat(value))
                ?.toFixed(1)
                .replace('.', ','),
        forcePrintString: true,
        forcePrintStyle: {
            textAlign: 'right',
        },
    },
    [COLUMN_TYPES.MONEY]: {
        Edit: ({ column }) => {
            const columnProps = column.columnDef;
            const name = getColumnName(columnProps);

            return <MoneyInput name={name} label={columnProps.header} size="small" fullWidth />;
        },
        convertString: formatMoney,
        forcePrintString: true,
        forcePrintStyle: {
            textAlign: 'right',
        },
    },
    [COLUMN_TYPES.CHECKBOX_BOOL]: {
        Cell: ({ renderedCellValue, table }) => {
            const compact = table.getState()?.density === 'compact';

            return (
                <Box style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                    <CheckboxBase
                        checked={renderedCellValue}
                        size={compact ? 'small' : 'medium'}
                        style={{
                            padding: 0,
                        }}
                        disabled
                    />
                </Box>
            );
        },
        Edit: ({ column }) => {
            const columnProps = column.columnDef;
            const name = getColumnName(columnProps);

            return (
                <Box style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                    <Checkbox name={name} label={columnProps.header} labelTooltip />
                </Box>
            );
        },
        convertString: value => (value ? '+' : '-'),
    },
    [COLUMN_TYPES.CHECKBOX_DATE]: {
        Cell: ({ renderedCellValue }) => <Date date={renderedCellValue} />,
        Edit: ({ renderedCellValue, column }) => {
            const columnProps = column.columnDef;
            const name = getColumnName(columnProps);

            return (
                <Box style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                    <Checkbox
                        name={name}
                        label={columnProps.header}
                        formatValue={checked =>
                            checked ? renderedCellValue || exportDate(null, true) : null
                        }
                        size="small"
                        labelTooltip
                    />
                </Box>
            );
        },
        convertString: dateToString,
    },
    [COLUMN_TYPES.USER]: {
        Cell: ({ row, table }) => {
            const userId = row?.original?.user_id;
            const density = table.getState()?.density;

            return (
                <Avatar
                    userId={userId}
                    tiny={density === 'compact'}
                    small={density === 'comfortable'}
                    inline
                />
            );
        },
        Edit: ({ column }) => {
            const columnProps = column.columnDef;
            const name = getColumnName(columnProps);

            return (
                <UserAutocomplete name={name} label={columnProps.header} size="small" fullWidth />
            );
        },
        convertString: (id, rowData) => rowData?.user?.display_name, // Requires usage of nestedSelectors: { model: 'user', selector: selectUsersById }
        forcePrintString: true,
    },
    [COLUMN_TYPES.ICON]: {
        Cell: ({ renderedCellValue: icon }) => <Icon>{icon}</Icon>,
        Edit: ({ column }) => {
            const columnProps = column.columnDef;
            const name = getColumnName(columnProps);

            return <IconSelect name={name} />;
        },
    },
    [COLUMN_TYPES.AVATAR_ICON]: {
        Cell: ({ renderedCellValue: icon, row, table }) => {
            const density = table.getState()?.density;

            if (icon) {
                return (
                    <BaseAvatar
                        color={row.original.color}
                        shortName={<Icon>{icon}</Icon>}
                        disableHover
                        tiny={density === 'compact'}
                        small={density === 'comfortable'}
                        outlined
                    />
                );
            }

            return null;
        },
        Edit: ({ column }) => {
            const columnProps = column.columnDef;
            const name = getColumnName(columnProps);

            return <IconSelect name={name} />;
        },
    },
    [COLUMN_TYPES.COLOR]: {
        Cell: ({ renderedCellValue: color }) => (
            <Color value={color && !color.startsWith('#') ? `#${color}` : color} />
        ),
        Edit: ({ column }) => {
            const columnProps = column.columnDef;
            const name = getColumnName(columnProps);

            return <ColorPicker name={name} label={columnProps.header} size="small" />;
        },
    },
    [COLUMN_TYPES.JSON]: {
        Edit: ({ column }) => {
            const columnProps = column.columnDef;
            const name = getColumnName(columnProps);

            return <JsonInput name={name} label={columnProps.header} />;
        },
    },
};

export const getColumnTypeConfig = (column, isEdit = false) => {
    const {
        type: columnType = COLUMN_TYPES.TEXT,
        Cell: CellComponent = null,
        Edit: EditComponent = null,
        convertString: columnConvertString = null,
        formatValue: formatString = null,
        ...restColumnConfig
    } = column;

    const {
        Cell: TypeCell,
        Edit: TypeEdit,
        convertString: typeConvertString,
        ...restTypeConfig
    } = COLUMN_TYPE_CONFIG[columnType];

    const convertStringFunc = columnConvertString || typeConvertString;

    const convertString = (value, rowData) => {
        if (typeof convertStringFunc === 'function') {
            const formatted = convertStringFunc(value, rowData);

            if (typeof formatString === 'function') {
                return formatString(formatted);
            }

            return formatted;
        }

        if (typeof formatString === 'function') {
            return formatString(value);
        }

        return value;
    };

    const convertStringComponentFunc = ({ renderedCellValue, row }) =>
        convertString(renderedCellValue, row?.original);

    const Cell = CellComponent || TypeCell || convertStringComponentFunc;
    const Edit = EditComponent || TypeEdit;

    return {
        ...(Cell ? { Cell } : {}),
        ...(isEdit && Edit ? { Edit } : {}),
        ...(convertStringFunc || formatString ? { convertString } : {}),
        ...restTypeConfig,
        ...restColumnConfig,
    };
};
