import { all, put, select, takeEvery } from 'redux-saga/effects';
import { removeItem, initList, insertItem } from './listsSlice';
import { selectListData } from './selectors';
import { calcPosition } from './utils';

const handlers = {};

function handleInitList(action) {
    const { listId, resource, criteria } = action.payload;
    if (criteria) {
        if (!handlers[resource]) {
            handlers[resource] = {};
        }

        if (!handlers[resource][listId]) {
            handlers[resource][listId] = criteria;
        }
    }
}

function handleItem(state, item, resourceHandlers) {
    return Object.entries(resourceHandlers).map(([listId, criteria]) => {
        const items = Object.values(selectListData(state, listId, true));
        const position = calcPosition(item, items, criteria, state);

        if (position === -1 && items) {
            const { __type: resource } = item;

            return put(removeItem(item.id, { listId, resource }));
        }

        return put(insertItem(item, { listId, position }));
    });
}

function* handleStoreFulfilled({ payload: item }) {
    if (item?.id) {
        const { __type: resource } = item;
        const resourceHandlers = handlers[resource];

        if (resourceHandlers) {
            const state = yield select();

            yield all(handleItem(state, item, resourceHandlers));
        }
    }
}

function* handleStoreBulkFulfilled({ payload: items }) {
    const { __type: resource } = items[0];
    const resourceHandlers = handlers[resource];
    if (resourceHandlers) {
        const state = yield select();

        const stateUpdates = items.reduce((carry, item) => {
            const updateBundle = handleItem(state, item, resourceHandlers);
            return [...carry, ...updateBundle];
        }, []);

        yield all(stateUpdates);
    }
}

function* handleDestroyFulfilled(action) {
    const { key: resource } = action.meta;
    if (resource) {
        yield put(removeItem(action.payload, { resource }));
    }
}

function* handleDestroyBulkFulfilled(action) {
    const { key: resource } = action.meta;
    if (resource) {
        yield all(action.payload.map(removedId => put(removeItem(removedId, { resource }))));
    }
}

function* handleAggregateFulfilled(action) {
    const { key: resource } = action.meta;
    if (resource) {
        const state = yield select();
        const resourceHandlers = handlers[resource];

        if (Array.isArray(action.payload)) {
            //ToDo grouped aggregates
        } else if (typeof action.payload === 'object') {
            yield all(
                handleItem(
                    state,
                    { ...action.payload, __type: resource, id: 'aggregations.total' },
                    resourceHandlers
                )
            );
        }
    }
}

export default [
    takeEvery(initList, handleInitList),
    takeEvery(action => /\/storeSocket$/.test(action.type), handleStoreFulfilled),
    takeEvery(action => /\/storeFulfilled$/.test(action.type), handleStoreFulfilled),
    takeEvery(action => /\/storeBulkFulfilled$/.test(action.type), handleStoreBulkFulfilled),
    takeEvery(action => /\/updateSocket$/.test(action.type), handleStoreFulfilled),
    takeEvery(action => /\/updateFulfilled$/.test(action.type), handleStoreFulfilled),
    takeEvery(action => /\/updateBulkFulfilled$/.test(action.type), handleStoreBulkFulfilled),
    takeEvery(action => /\/restoreSocket$/.test(action.type), handleStoreFulfilled),
    takeEvery(action => /\/restoreFulfilled$/.test(action.type), handleStoreFulfilled),
    takeEvery(action => /\/destroyFulfilled$/.test(action.type), handleDestroyFulfilled),
    takeEvery(action => /\/destroyBulkFulfilled$/.test(action.type), handleDestroyBulkFulfilled),
    takeEvery(action => /\/bulkSocket$/.test(action.type), handleStoreBulkFulfilled),
    takeEvery(action => /\/aggregateFulfilled$/.test(action.type), handleAggregateFulfilled),
];
