import {
    differenceInDays,
    endOfWeek,
    format,
    formatISO,
    formatRelative,
    getDay,
    getUnixTime,
    hoursToMinutes,
    isDate,
    isFriday,
    isMonday,
    isSaturday,
    isSunday,
    isThursday,
    isTuesday,
    isValid,
    isWednesday,
    nextFriday,
    nextMonday,
    nextSaturday,
    nextSunday,
    nextThursday,
    nextTuesday,
    nextWednesday,
    parse,
    set,
    startOfDay,
    startOfWeek,
    subDays,
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { de, enUS } from 'date-fns/locale';

/**
 * Wir benutzen die Libraries date-fns und date-fns-tz.
 * Für die meisten Fälle reichen die Funktionen, hier gibt es Helpers um Standards zu etablieren
 * https://date-fns.org/docs/Getting-Started
 * https://github.com/marnusw/date-fns-tz
 */

const IsoSuffix = '.000000Z';
export const PATTERN = {
    // ToDo: I18N?
    localDate: 'dd.MM.yyyy',
    localDateLong: 'eeee, dd. LLLL yyyy',
    localTime: 'HH:mm',
    localTimeLong: 'HH:mm:ss',
    localDateTime: 'dd.MM.yyyy HH:mm',
    usDate: 'yyyy-MM-dd',
    week: 'II',
    day: 'dd',
    weekday: 'EEEE',
    weekdayShort: 'E',
};

const LOCALE_MAP = {
    de: de,
    en: enUS,
};

/**
 * Get a DateFNS compatible locale object from string(2 chars).
 * The string is available in useTranslation (i18n.language)
 * @param localeString
 * @return {Locale|de}
 */

export const getDateFnsLocale = localeString => {
    if (localeString in LOCALE_MAP) {
        return LOCALE_MAP[localeString];
    }

    return LOCALE_MAP.de;
};

/**
 *
 * @param dateString
 * @param createWhenEmpty
 * @return {null|Date}
 */

export const importDate = (dateString, createWhenEmpty = false) => {
    const finalDate = !dateString && createWhenEmpty ? new Date() : dateString;

    if (typeof finalDate === 'string' && finalDate !== '') {
        return new Date(finalDate);
    }

    if (finalDate instanceof Date) {
        return finalDate;
    }

    return null;
};

export const exportDate = (date, createWhenEmpty = false) => {
    const finalDate = !date && createWhenEmpty ? new Date() : date;

    if (finalDate instanceof Date) {
        const iso = finalDate.toISOString();
        return iso.slice(0, iso.length - 5); // Remove [...].000Z();
    }

    if (typeof finalDate === 'string' && finalDate !== '') {
        return finalDate; // validate?
    }

    return null;
};

/**
 * ISO Date-Time for Inputs
 * @returns {string}
 */
export const getCurrentISODate = () => {
    return formatISO(new Date());
};

/**
 * Get full data of current timezone
 * @returns {{offset: number, name: string}}
 */
export const getCurrentTimeZone = () => {
    const date = new Date();
    return {
        name: Intl.DateTimeFormat().resolvedOptions().timeZone,
        offset: date.getTimezoneOffset(),
    };
};

// --- UTC DATE ---
/**
 * Convert Dates to UTC, prepared for server submission
 * @param date {Date}
 * @param timezone
 * @returns {string}
 */
export const convertLocalDateToUTC = (date = new Date(), timezone = null) => {
    const utc = zonedTimeToUtc(date, timezone || getCurrentTimeZone().name).toISOString();
    return utc.substr(0, utc.length - 5); // Remove [...].000Z
};

/**
 * Convert UTC strings (from Server) to date
 * @param utc {string}
 * @param timezone
 * @returns {Date}
 */
export const convertUTCtoLocalDate = (utc, timezone = null) => {
    if (typeof utc !== 'string') {
        return null;
    }

    const fullString =
        utc.substr(utc.length - 8, utc.length) === IsoSuffix ? utc : `${utc}${IsoSuffix}`;
    return utcToZonedTime(fullString, timezone || getCurrentTimeZone().name);
};

export const dateToString = value => {
    if (isDate(value) && isValid(value)) {
        return formatLocalDateTime(value);
    }

    if (typeof value === 'string' && value.split('-').length > 1 && value.split(':').length > 1) {
        const imported = importDate(value);

        if (imported && isDate(imported) && isValid(imported) && getUnixTime(imported) !== 0) {
            return formatLocalDateTime(imported);
        }
    }

    return value;
};

// --- FORMAT ---
export const formatLocalDateTime = (date = new Date(), locale = de) => {
    return format(date, PATTERN.localDateTime, { locale });
};

export const formatLocalDate = (date = new Date(), long = false, locale = de) => {
    return format(date, long ? PATTERN.localDateLong : PATTERN.localDate, { locale });
};

export const formatDate = (date = new Date(), locale = de) => {
    return format(date, PATTERN.usDate, { locale });
};

export const formatTime = (date = new Date()) => {
    return format(date, PATTERN.localTime);
};

export const formatTimeLong = (date = new Date()) => {
    return format(date, PATTERN.localTimeLong);
};

export const formatWeekOfYear = (date = new Date()) => {
    return format(date, PATTERN.week);
};

export const formatDay = (date = new Date()) => {
    return format(date, PATTERN.day);
};

export const formatWeekday = (date = new Date(), short = false) => {
    return format(date, short ? PATTERN.weekdayShort : PATTERN.weekday);
};

export const formatDateForFilename = (date = new Date()) => {
    return format(date, 'yyMMdd_HHmm');
};

export const parseTime = timeString => {
    if (!timeString) {
        return { hours: 0, minutes: 0 };
    }

    const [hours, minutes] = timeString.includes(':')
        ? timeString.split(':')
        : ['0', (Number.parseInt(timeString) * 60).toString()];

    // eslint-disable-next-line radix
    return { hours: parseInt(hours), minutes: minutes ? parseInt(minutes) : 0 };
};

// --- SEPARATED DATE STRING HANDLING ---
export const splitDateTime = (date = new Date()) => {
    return {
        date: formatDate(date),
        time: formatTime(date),
    };
};

export const assembleLocal = (date, time) => {
    const intTime = parseTime(time);
    const dateFull = typeof date === 'string' ? parse(date, PATTERN.usDate, new Date()) : date;
    return set(dateFull, intTime);
};

export const getWeek = (date, weekDays = 7) => {
    const referenceDate = startOfDay(date);
    const start = isMonday(referenceDate) ? referenceDate : nextMonday(subDays(referenceDate, 7));
    let end;
    switch (weekDays) {
        default:
            end = isSunday(referenceDate) ? referenceDate : nextSunday(referenceDate);
            break;
        case 6:
            end = isSaturday(referenceDate) ? referenceDate : nextSaturday(referenceDate);
            break;
        case 5:
            end = isFriday(referenceDate) ? referenceDate : nextFriday(referenceDate);
            break;
        case 4:
            end = isThursday(referenceDate) ? referenceDate : nextThursday(referenceDate);
            break;
        case 3:
            end = isWednesday(referenceDate) ? referenceDate : nextWednesday(referenceDate);
            break;
        case 2:
            end = isTuesday(referenceDate) ? referenceDate : nextTuesday(referenceDate);
            break;
        case 1:
            end = start;
            break;
    }
    return { start, end };
};

export const getWeekRange = date => {
    const referenceDate = startOfDay(date);
    return [
        isMonday(referenceDate) ? referenceDate : nextMonday(subDays(referenceDate, 7)),
        isTuesday(referenceDate) ? referenceDate : nextTuesday(referenceDate),
        isWednesday(referenceDate) ? referenceDate : nextWednesday(referenceDate),
        isThursday(referenceDate) ? referenceDate : nextThursday(referenceDate),
        isFriday(referenceDate) ? referenceDate : nextFriday(referenceDate),
        isSaturday(referenceDate) ? referenceDate : nextSaturday(referenceDate),
        isSunday(referenceDate) ? referenceDate : nextSunday(referenceDate),
    ];
};

// Date-Range inclunding week days
export const getWeekendDaysInRange = (dateStart, dateEnd, weekDays = 7) => {
    if (weekDays === 7) {
        return 0;
    }

    const base = getDay(dateStart);
    const dayOfWeek = base === 0 ? 7 : base;

    const days = differenceInDays(dateEnd, dateStart);
    const weeks = Math.floor(days / 7);
    const rest = days % 7;

    const weekendLength = 7 - weekDays;
    const fullWeekendDays = weekendLength * weeks;

    const range = dayOfWeek + rest;
    const nextWeek = range % 7;

    const nextWeekend = nextWeek - weekDays;
    const thisWeekendMissed = dayOfWeek - weekDays;
    const thisWeekend = range - nextWeek - weekDays - thisWeekendMissed;

    return (
        fullWeekendDays + (nextWeekend > 0 ? nextWeekend : 0) + (thisWeekend > 0 ? thisWeekend : 0)
    );
};

export const isValidDate = date => {
    return date instanceof Date && !Number.isNaN(date.valueOf());
};

export const firstWeekDay = date => startOfWeek(date, { weekStartsOn: 1 });

export const lastWeekDay = date => endOfWeek(date, { weekStartsOn: 1 });

export const generateRelativeDate = date => {
    return formatRelative(date, new Date(), { locale: de, weekStartsOn: 1 });
};

export const dateFromAny = date => {
    if (isValidDate(date)) {
        return date;
    }

    if (typeof date === 'string') {
        return convertUTCtoLocalDate(date);
    }

    return null;
};

const isIntString = (value = null) => {
    if (!value) {
        return false;
    }
    const regEx = /^\d+$/;

    return regEx.test(value);
};

const getHoursAndMinutesFormSeconds = seconds => {
    const intSec = typeof seconds === 'string' ? Number.parseInt(seconds) : seconds;
    const minutesRaw = Math.ceil(intSec / 60);
    const hours = Math.floor(minutesRaw / 60);
    const minutes = minutesRaw % 60;

    return { hours, minutes };
};

export const formatDurationText = (seconds = 0) => {
    const { hours, minutes } = getHoursAndMinutesFormSeconds(seconds);
    return `${hours}h ${minutes}m`;
};

export const formatDuration = (seconds = 0) => {
    const { hours, minutes } = getHoursAndMinutesFormSeconds(seconds);
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};

export const parseDuration = (duration = '0:00') => {
    const parsedDuration = parseTime(duration);

    return (parsedDuration.minutes + hoursToMinutes(parsedDuration.hours)) * 60;
};

export const verifiedDurationInt = value => {
    if (value) {
        if (typeof value === 'string') {
            if (value.includes(':')) {
                return parseDuration(value);
            }

            if (isIntString(value)) {
                return hoursToMinutes(Number.parseInt(value)) * 60;
            }
        }

        if (Number.isInteger(value)) {
            return value;
        }
    }

    return null;
};
