import { isEqual } from 'lodash';
import {
    selectListOrderBy,
    selectListPage,
    selectListParams,
    selectListResource,
    selectListSearch,
    selectListStaticParams,
} from './selectors';
import { saveOrderBy, saveSearch, searchError, searchFulfilled, searchPending } from './listsSlice';
import { formatModelResource } from './utils';
import { bulkFulfilledAction } from '../api/actions';

export const indexPage =
    (listId, page = 1, params = {}, meta = null, useIndex = false, force = true) =>
    (dispatch, getState, api) => {
        const state = getState();
        const resource = selectListResource(state, listId);
        const search = selectListSearch(state, listId);
        const orderBy = selectListOrderBy(state, listId);
        const staticParams = selectListStaticParams(state, listId);
        const lastParams = selectListParams(state, listId);

        if (!resource) {
            throw new Error(`List "${listId}" must be initialized first!`);
        }

        const prepped = {
            limit: 25,
            page,
            orderBy,
            ...staticParams,
            ...search,
            ...params,
        };

        if (!force && isEqual(prepped, lastParams)) {
            return Promise.resolve();
        }

        dispatch(searchPending({ listId, page, params: prepped }));

        const apiCall = useIndex ? api[resource].index : api[resource].search;
        const requestTime = new Date();

        return apiCall(prepped, { ...meta, listId }, listId)
            .then(response => {
                dispatch(applyWithShallow(prepped, response));
                dispatch(searchFulfilled(response.data, { ...response.meta, listId, requestTime }));
                return response;
            })
            .catch(err => {
                if (!err.canceled) {
                    dispatch(searchError(err, { listId }));
                }
                throw err;
            });
    };

export const refreshPage = listId => (dispatch, getState) => {
    const page = selectListPage(getState(), listId);

    return dispatch(indexPage(listId, page));
};

export const filterList =
    (listId, search, orderBy = null, params = {}, meta = {}, useIndex = false) =>
    dispatch => {
        if (orderBy !== null) {
            dispatch(saveOrderBy(orderBy, { listId }));
        }
        dispatch(saveSearch(search, { listId }));

        return dispatch(indexPage(listId, 1, params, { ...meta, reset: true }, useIndex));
    };

export const suggestList = (listId, params) => (dispatch, getState, api) => {
    const resource = selectListResource(getState(), listId);

    if (!resource) {
        return Promise.resolve({ data: [], meta: {} });
    }

    return api[resource].suggest(params);
};

export const aggregateList = (listId, params) => (dispatch, getState, api) => {
    const resource = selectListResource(getState(), listId);

    if (!resource) {
        return Promise.resolve({ data: [], meta: {} });
    }

    return api[resource].aggregate(params);
};

export const applyWithShallow = (params, response) => dispatch => {
    if (params?.with_shallow) {
        const models = params.with_shallow.includes(',')
            ? params.with_shallow.split(',')
            : [params.with_shallow];

        models.forEach(modelRaw => {
            const model = modelRaw.trim();

            if (response?.extraData && model in response.extraData) {
                const shallowList = response.extraData[model];

                if (Array.isArray(shallowList) && shallowList.length) {
                    const modelResource = formatModelResource(model);

                    dispatch(bulkFulfilledAction(modelResource, 'search', {}, shallowList, {}));
                }
            }
        });
    }
};
