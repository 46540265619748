import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Schedule as ScheduleIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useField } from 'formik';
import { getFormFieldError } from '../../../modules/loading/utils';
import { ErrorPropType } from '../../../modules/loading/proptypes';
import ContextualCan from '../../../modules/abilities/ContextualCan';
import { TimePicker } from '@mui/x-date-pickers';
import { READ, WRITE } from '../../../modules/abilities/actions';
import { useContextualCan } from '../../../modules/abilities/hooks';
import TextField from '../base/TextField';

const useStyles = makeStyles({
    input: {
        margin: 0,
        width: 128,
    },

    fullWidth: {
        width: '100%',
    },
});

const TimeInput = ({
    name,
    label,
    errors,
    variant,
    contrast,
    fullWidth,
    disabled,
    can,
    ...other
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const canDo = useContextualCan(can ? can : WRITE, name);

    const [{ value }, meta, { setValue, setError, setTouched }] = useField(name);
    const error = getFormFieldError(name, errors, meta);

    return (
        <ContextualCan I={READ} field={name}>
            <TimePicker
                id={name}
                ampm={false}
                value={value}
                onChange={time => setValue(time)}
                onError={err => {
                    if (err !== meta.error) {
                        setError(err);
                    }
                }}
                className={`${classes.input} ${fullWidth ? classes.fullWidth : ''}`}
                disabled={disabled || !canDo}
                renderInput={params => (
                    <TextField
                        {...params}
                        name={name}
                        value={value}
                        size="small"
                        label={label}
                        variant="outlined"
                        error={!!error}
                        helperText={error && t(`errors.${error.split('.').join('')}`)}
                        onBlur={() => setTouched(true, true)}
                    />
                )}
                {...other}
            />
        </ContextualCan>
    );
};

TimeInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    errors: ErrorPropType,
    variant: PropTypes.string,
    contrast: PropTypes.bool,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    can: PropTypes.string,
};

TimeInput.defaultProps = {
    label: null,
    errors: null,
    variant: 'dense',
    contrast: false,
    fullWidth: false,
    disabled: false,
    can: null,
};

export default TimeInput;
