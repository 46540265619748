import React, { memo, useEffect, useMemo, useState } from 'react';
import { Appointments } from '@devexpress/dx-react-scheduler-material-ui';
import Typography from '@mui/material/Typography';
import { useAppointmentCalendar } from './AppointmentCalendarContext';
import { useSelector } from 'react-redux';
import { selectAllEmployees } from '../../modules/employees/employeesSlice';
import { selectAllResources } from '../../modules/resources/resourcesSlice';
import {
    formatAppointmentImport,
    formatAppointmentTypeResourceGroups,
    generateAppointmentColor,
} from '../../modules/appointments/utils';
import { makeStyles, useTheme } from '@mui/styles';
import { Grid } from '@mui/material';
import RepeatIcon from '@mui/icons-material/Repeat';
import CardIdentifierDisplay from '../card/CardIdentifierDisplay';
import Box from '@mui/material/Box';
import CategoryBox from '../categories/CategoryBox';
import { selectAppointmentTypeById } from '../../modules/appointmentTypes/appointmentTypeSlice';
import { EMPLOYEE_RESOURCE, RESOURCE_RESOURCE } from '../../modules/api/resources';
import { buildStripedBackground, formatFlatResourceKey } from '../../modules/calendar/utils';
import CalendarDetailResource from './detail/CalendarDetailResource';
import ItemStack from '../icons/ItemStack';
import { selectPriorityById } from '../../modules/priority/prioritySlice';
import Priority from '../priorities/Priority';
import { importDate, formatTime } from '../../modules/datetime/utils';
import { useAppointmentName } from '../../modules/appointments/hooks';
import { selectAppointmentById } from '../../modules/appointments/appointmentSlice';
import SizeWatcher, { SIZE_WATCH_MODES } from '../layout/SizeWatcher';
import { differenceInDays } from 'date-fns';

const useStyles = makeStyles(theme => ({
    container: {
        width: 'calc(100% - 4px)',
        height: 'calc(100% - 4px)',
        margin: 2,
        flexWrap: 'nowrap',
        opacity: 1,
    },

    shadowContent: {
        position: 'absolute',
        top: -6,
        bottom: -6,
        left: -6,
        right: 0,
        boxShadow: '0px 0px 4px 5px inset white',
        pointerEvents: 'none',
    },

    category: {
        minWidth: 8,
        maxWidth: 8,
        marginTop: -2,
        marginBottom: -2,
        marginLeft: -2,
    },

    main: {
        flex: 1,
        height: '100%',
        maxHeight: 256,
        alignContent: 'start',
        paddingRight: 18,
    },

    title: {
        fontSize: 10,
        lineHeight: '10px',
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
    },

    repeatIcon: {
        height: 10,
        width: 10,
    },

    repeatText: {
        fontSize: 8,
        lineHeight: '8px',
    },

    text: {
        color: theme.palette.grey[500],
        fontSize: 10,
        lineHeight: '10px',
        whiteSpace: 'nowrap',
    },

    avatars: {
        position: 'absolute',
        maxWidth: 68,
        maxHeight: '100%',
        margin: 1,
        right: 0,
        top: 0,
    },
}));

const HEIGHT_TYPE = {
    FULL: 'ful',
    MEDIUM: 'lge',
    TINY: 'tny',
};

const HEIGHT_OPTION = {
    [HEIGHT_TYPE.MEDIUM]: 64,
    [HEIGHT_TYPE.TINY]: 38,
};

const MAX_HEIGHT = 66;

const AppointmentAdvanced = ({ data, children, ...other }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [currentHeight, setCurrentHeight] = useState(null);
    const [originalId, setOriginalId] = useState(data?.originalId || null);
    const [resourceOwner, setResourceOwner] = useState(data?.resourceOwner || null);

    const {
        showColor,
        selectedEmployees,
        selectedResources,
        defaultEntityResource,
        defaultEntityId,
    } = useAppointmentCalendar();

    const allEmployees = useSelector(selectAllEmployees);
    const allResources = useSelector(selectAllResources);

    const employees = useMemo(
        () => allEmployees.filter(employee => selectedEmployees.includes(employee.id)),
        [allEmployees, selectedEmployees]
    );

    const resources = useMemo(
        () => allResources.filter(resource => selectedResources.includes(resource.id)),
        [allResources, selectedResources]
    );

    const color = useMemo(() => {
        return generateAppointmentColor(data, employees, resources);
    }, [data, employees, resources]);

    const originalAppointment = useSelector(state => selectAppointmentById(state, originalId));
    const fullAppointment = useMemo(() => {
        if (originalAppointment) {
            return formatAppointmentImport(originalAppointment);
        }

        return null;
    }, [originalAppointment]);

    const {
        id,
        title,
        address,

        startDate,
        endDate,

        rRule,
        rCount,
        rIndex,

        appointmentTypeId,
        absenceTypeId,
        categoryId,
        priorityId,

        task,
        card,

        completedAt,
        __type,
    } = fullAppointment || {};

    const titleText = useAppointmentName({
        id: originalId || id,
        title,
        task,
        appointment_type_id: appointmentTypeId,
        absence_type_id: absenceTypeId,
        __type,
    });

    const startsAt = useMemo(() => importDate(startDate), [startDate]);

    const endsAt = useMemo(() => importDate(endDate), [endDate]);

    const overADay = useMemo(() => differenceInDays(endsAt, startsAt) >= 1, [startsAt, endsAt]);

    const currentCategoryId = categoryId || task?.categoryId;
    const currentPriorityId = priorityId || task?.priorityId;

    const priority = useSelector(state => selectPriorityById(state, currentPriorityId));
    const isMaxPriority = priority?.order === 1;
    const isSizeTimeBound = useMemo(() => {
        if (Array.isArray(children) && children.length >= 2) {
            const type = children[1]?.props?.type;
            if (type) {
                return type !== 'horizontal';
            }
        }
        return false;
    }, [children]);

    const isDefaultEntity = useMemo(() => {
        if (defaultEntityId && defaultEntityResource && resourceOwner) {
            const { id: resourceId, resource: resourceType } = resourceOwner;
            return (
                resourceId &&
                resourceType &&
                resourceId === defaultEntityId &&
                resourceType === defaultEntityResource
            );
        }
        return true;
    }, [resourceOwner]);

    const addressText = useMemo(() => (address || card?.address)?.short, [address, card]);

    const heightOption = useMemo(() => {
        if (currentHeight && currentHeight <= HEIGHT_OPTION[HEIGHT_TYPE.TINY]) {
            return HEIGHT_TYPE.TINY;
        }

        if (currentHeight && currentHeight <= HEIGHT_OPTION[HEIGHT_TYPE.MEDIUM]) {
            return HEIGHT_TYPE.MEDIUM;
        }

        return HEIGHT_TYPE.FULL;
    }, [currentHeight]);

    const isVertical = useMemo(
        () => heightOption === HEIGHT_TYPE.MEDIUM || heightOption === HEIGHT_TYPE.FULL,
        [heightOption]
    );

    const appointmentType = useSelector(state =>
        selectAppointmentTypeById(state, appointmentTypeId)
    );

    const style = useMemo(() => {
        const getBackgroundProps = () => {
            if (appointmentType?.overlap === 'deny') {
                return {
                    background: buildStripedBackground('white', '#ffdfdf'),
                    backgroundSize: '8.00px 8.00px',
                };
            }

            return {
                backgroundColor: 'white',
            };
        };

        return {
            cursor: 'pointer',
            opacity: isDefaultEntity ? 1 : 0.6,
            border: `1px solid ${showColor ? color : theme.palette.primary.light}`,
            transition: 'opacity .2s',
            ...(isSizeTimeBound ? {} : { maxHeight: MAX_HEIGHT }),
            ...(completedAt
                ? {
                      opacity: 0.5,
                      textDecoration: 'line-through',
                  }
                : {}),
            ...getBackgroundProps(),
        };
    }, [appointmentType, isDefaultEntity, showColor, color, theme, isSizeTimeBound, completedAt]);

    const { groupKeys, groupConfig } = useMemo(
        () =>
            formatAppointmentTypeResourceGroups(
                appointmentType,
                group => !(group?.required || group?.default || group?.showOnAppointment)
            ),
        [appointmentType]
    );

    const resourceInstances = useMemo(() => {
        return groupKeys.reduce((carry, groupKey) => {
            if (groupKey in groupConfig && data) {
                const config = groupConfig[groupKey];

                const showEvenWhenEmpty = config?.required;
                const showWhenSet = config?.default || config?.showOnAppointment;

                if (!(showEvenWhenEmpty || showWhenSet)) {
                    return carry;
                }

                const idMixed =
                    fullAppointment.grouped && groupKey in fullAppointment.grouped
                        ? fullAppointment.grouped[groupKey]
                        : [];
                const ids = Array.isArray(idMixed) ? idMixed : [idMixed];

                if (ids.length === 0) {
                    if (showEvenWhenEmpty) {
                        const key = `emptyRes.${config.group}.${fullAppointment.id}`;

                        return [
                            ...carry,
                            {
                                key,
                                component: (
                                    <CalendarDetailResource
                                        key={key}
                                        flatKey={'empty'}
                                        groupConfig={config}
                                        style={{
                                            marginRight: 5,
                                        }}
                                        tiny
                                    />
                                ),
                            },
                        ];
                    }

                    return carry;
                }

                return [
                    ...carry,
                    ...ids.reduce((prevInstances, id) => {
                        if ([EMPLOYEE_RESOURCE, RESOURCE_RESOURCE].includes(config.api)) {
                            const flatKey = formatFlatResourceKey(id, config.api).key;

                            if (
                                resourceOwner &&
                                config.api === resourceOwner.resource &&
                                id === resourceOwner.id
                            ) {
                                return prevInstances;
                            }

                            return [
                                ...prevInstances,
                                {
                                    key: `appointmentResource.${id}.${groupKey}.${flatKey}`,
                                    component: (
                                        <CalendarDetailResource
                                            key={`appointmentResourceWrap.${id}.${groupKey}.${flatKey}`}
                                            flatKey={flatKey}
                                            groupConfig={config}
                                            style={{
                                                marginRight: 5,
                                            }}
                                            tiny
                                        />
                                    ),
                                },
                            ];
                        }
                    }, []),
                ];
            }

            return carry;
        }, []);
    }, [fullAppointment, groupKeys, groupConfig]);

    const handleSize = ({ height }) => {
        if (height !== currentHeight) {
            setCurrentHeight(height);
        }
    };

    useEffect(() => {
        const { originalId: id, resourceOwner: owner } = data;

        if (originalId !== id) {
            setOriginalId(id);
        }

        if (resourceOwner !== owner) {
            setResourceOwner(owner);
        }
    }, [data, originalId, resourceOwner]);

    return (
        <Appointments.Appointment {...other} data={data} style={style}>
            <SizeWatcher
                mode={SIZE_WATCH_MODES.PARENT}
                onSizeChange={handleSize}
                onlyOnce
                fullHeight
            >
                <Grid container direction="row" className={classes.container}>
                    {currentCategoryId ? (
                        <Grid item className={classes.category}>
                            <CategoryBox categoryId={currentCategoryId} />
                        </Grid>
                    ) : null}

                    <Grid
                        item
                        style={{
                            maxWidth: resourceInstances.length > 0 ? 'calc(100% - 32px)' : '100%',
                        }}
                    >
                        <Grid
                            container
                            className={classes.main}
                            wrap={isVertical ? 'nowrap' : 'wrap'}
                            direction={isVertical ? 'column' : 'row'}
                        >
                            <Grid item>
                                <Grid container>
                                    {rRule || isMaxPriority ? (
                                        <Grid item m={'2px'}>
                                            <Grid container alignItems="center">
                                                {rRule ? (
                                                    <>
                                                        <RepeatIcon
                                                            className={classes.repeatIcon}
                                                        />
                                                        {!Number.isNaN(rIndex) ? (
                                                            <Typography
                                                                className={classes.repeatText}
                                                            >
                                                                {rCount
                                                                    ? `${rIndex + 1}/${rCount}`
                                                                    : rIndex}
                                                            </Typography>
                                                        ) : null}
                                                    </>
                                                ) : null}
                                                {isMaxPriority ? (
                                                    <Priority
                                                        priorityId={currentPriorityId}
                                                        size="tiny"
                                                        onlyIcon
                                                    />
                                                ) : null}
                                            </Grid>
                                        </Grid>
                                    ) : null}

                                    {card?.idOrder || card?.idOffer ? (
                                        <Grid item m={'2px'}>
                                            <Typography className={classes.title}>
                                                <CardIdentifierDisplay rawCard={card} compact />
                                            </Typography>
                                        </Grid>
                                    ) : null}
                                </Grid>
                            </Grid>
                            <Grid item m={'2px'}>
                                <Typography className={classes.title}>{titleText}</Typography>
                            </Grid>
                            {isSizeTimeBound && (startsAt || endsAt) ? (
                                <Grid item m={'2px'}>
                                    <Typography className={classes.text}>
                                        {startsAt ? formatTime(startsAt) : null}
                                        {startsAt && endsAt ? ' - ' : null}
                                        {endsAt ? formatTime(endsAt) : null}
                                    </Typography>
                                </Grid>
                            ) : null}
                            <Grid item m={'2px'}>
                                {addressText ? (
                                    <Typography className={classes.text}>{addressText}</Typography>
                                ) : null}
                            </Grid>
                        </Grid>
                    </Grid>

                    {resourceInstances.length > 0 ? (
                        <Grid item className={classes.avatars}>
                            <Box
                                style={{
                                    height: '100%',
                                    maxHeight: 256,
                                    marginTop: isVertical ? 6 : 4,
                                }}
                            >
                                <Box
                                    style={{
                                        display: 'flex',
                                        backgroundColor: 'white',
                                        boxShadow: '0 0 8px 8px white',
                                        borderRadius: 12,
                                        alignItems:
                                            heightOption === HEIGHT_TYPE.TINY ? 'center' : 'start',
                                    }}
                                >
                                    {resourceInstances.length > 2 ? (
                                        <ItemStack
                                            items={resourceInstances.slice(
                                                0,
                                                resourceInstances.length > 3 && overADay ? 5 : 3
                                            )}
                                            itemSize={22}
                                        />
                                    ) : (
                                        resourceInstances.map(({ component }) => component)
                                    )}
                                </Box>
                            </Box>
                        </Grid>
                    ) : null}
                </Grid>
            </SizeWatcher>
            <Box className={classes.shadowContent} />
        </Appointments.Appointment>
    );
};

AppointmentAdvanced.propTypes = {};

export default memo(AppointmentAdvanced);
