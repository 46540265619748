import React, { memo, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CalendarDetailHead from './detail/CalendarDetailHead';
import TaskLinks from '../tasks/TaskLinks';
import CalendarDetailBasics from './detail/CalendarDetailBasics';
import CalendarDetailSummary from './detail/CalendarDetailSummary';
import { Grid } from '@mui/material';
import CalendarDetailChange from './detail/CalendarDetailChange';
import { differenceInDays } from 'date-fns';
import { importDate } from '../../modules/datetime/utils';
import CalendarDetailResources from './form/CalendarDetailResources';
import { makeStyles } from '@mui/styles';
import { useAppointmentCalendar } from './AppointmentCalendarContext';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllEmployees } from '../../modules/employees/employeesSlice';
import { selectAllResources } from '../../modules/resources/resourcesSlice';
import {
    formatAppointmentExport,
    formatAppointmentImport,
    generateAppointmentColor,
} from '../../modules/appointments/utils';
import Box from '@mui/material/Box';
import Form from '../form/formik/Form';
import {
    selectAppointmentById,
    updateAppointment,
} from '../../modules/appointments/appointmentSlice';
import { APPOINTMENT_RESOURCE } from '../../modules/api/resources';
import AppointmentResponsibles from './form/AppointmentResponsibles';
import { useAppointmentName } from '../../modules/appointments/hooks';

const useStyles = makeStyles({
    section: {
        maxWidth: 'calc(100% - 32px) !important',
    },
});

export const CHANGES = {
    CREATE: 'create',
    UPDATE: 'update',
    PLAN: 'plan',
    DONE: 'done',
};

const CalendarDetail = ({ appointmentData }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { selectedEmployees, selectedResources, handleDelete } = useAppointmentCalendar();

    const allEmployees = useSelector(selectAllEmployees);
    const allResources = useSelector(selectAllResources);

    const originalAppointment = useSelector(state =>
        selectAppointmentById(state, appointmentData?.originalId)
    );

    const {
        id,
        originalId,
        title,
        notes,
        address,

        locked,
        startDate,
        endDate,
        allDay,

        rRule,
        rCount,
        rIndex,

        appointmentTypeId,
        absenceTypeId,

        categoryId,
        priorityId,
        card,
        task,

        plannedAt,
        plannedBy,
        completedAt,
        completedBy,
        createdAt,
        createdBy,
        updatedAt,
        updatedBy,
        __type,
    } = useMemo(
        () =>
            originalAppointment
                ? { ...appointmentData, ...formatAppointmentImport(originalAppointment) }
                : appointmentData,
        [appointmentData, originalAppointment]
    );

    const titleText = useAppointmentName({
        id: originalId || id,
        title,
        task,
        appointment_type_id: appointmentTypeId,
        absence_type_id: absenceTypeId,
        __type,
    });

    const employees = useMemo(
        () => allEmployees.filter(employee => selectedEmployees.includes(employee.id)),
        [allEmployees, selectedEmployees]
    );

    const resources = useMemo(
        () => allResources.filter(resource => selectedResources.includes(resource.id)),
        [allResources, selectedResources]
    );

    const formatChangeText = (type, displayName, dateText, date) => {
        const typeKey = Object.values(CHANGES).includes(type) ? type : 'change';
        const actionText = t(`components.CalendarDetail.changes.${typeKey}`);
        const realDate = importDate(date);
        const timeText =
            Math.abs(differenceInDays(new Date(), realDate)) > 6
                ? t('components.CalendarDetail.changes.on')
                : '';

        return `${actionText} ${timeText} ${dateText}`;
    };

    const appointmentColor = useMemo(
        () => generateAppointmentColor(appointmentData, employees, resources),
        [appointmentData, employees, resources]
    );

    const initialResources = useMemo(
        () => ({
            id: appointmentData.id,
            originalId: appointmentData.originalId,
            grouped: appointmentData.grouped,
            appointmentTypeId: appointmentData.appointmentTypeId,
        }),
        [appointmentData]
    );

    const handleResources = values => {
        const formatted = formatAppointmentExport({
            id: values.id,
            originalId: values.originalId,
            grouped: values.grouped,
            form: true,
        });

        return dispatch(updateAppointment(formatted));
    };

    return (
        <>
            <Box
                style={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    top: 0,
                    border: `2px solid ${appointmentColor}`,
                    borderRadius: 4,
                    pointerEvents: 'none',
                }}
            />
            <Grid container direction="column">
                <Grid item className={classes.section}>
                    <CalendarDetailHead
                        appointmentId={originalId}
                        categoryId={categoryId || task?.categoryId}
                        priorityId={priorityId || task?.priorityId}
                        rRule={rRule}
                        rCount={rCount}
                        rIndex={rIndex}
                        completedAt={completedAt}
                        onDelete={handleDelete}
                    />
                </Grid>
                {task.id && card.id ? (
                    <Grid item className={classes.section}>
                        <TaskLinks task={task} card={card} />
                    </Grid>
                ) : null}
                <Grid item className={classes.section}>
                    <CalendarDetailBasics
                        title={titleText}
                        locked={locked}
                        startDate={startDate}
                        endDate={endDate}
                        allDay={allDay}
                        address={address || card?.address}
                        color={appointmentColor}
                    />
                </Grid>
                <Grid item className={classes.section} m={2}>
                    <Form
                        initialValues={initialResources}
                        onSubmit={handleResources}
                        subject={originalAppointment || APPOINTMENT_RESOURCE}
                        preventInitialSubmit
                        autoSubmit
                    >
                        <CalendarDetailResources />
                    </Form>
                </Grid>
                <CalendarDetailSummary
                    notes={notes}
                    task={task}
                    wrapper={({ children }) => (
                        <Grid item className={classes.section}>
                            {children}
                        </Grid>
                    )}
                />
                <Grid item className={classes.section} m={2}>
                    <CalendarDetailChange
                        date={createdAt}
                        userId={createdBy}
                        onFormatText={(displayName, dateText) =>
                            formatChangeText(CHANGES.CREATE, displayName, dateText, createdAt)
                        }
                    />
                    {updatedAt && updatedBy ? (
                        <CalendarDetailChange
                            date={updatedAt}
                            userId={updatedBy}
                            onFormatText={(displayName, dateText) =>
                                formatChangeText(CHANGES.UPDATE, displayName, dateText, updatedAt)
                            }
                        />
                    ) : null}
                    {plannedAt && plannedBy ? (
                        <CalendarDetailChange
                            date={plannedAt}
                            userId={plannedBy}
                            onFormatText={(displayName, dateText) =>
                                formatChangeText(CHANGES.PLAN, displayName, dateText, plannedAt)
                            }
                        />
                    ) : null}
                    {completedAt && completedBy ? (
                        <CalendarDetailChange
                            date={completedAt}
                            userId={completedBy}
                            onFormatText={(displayName, dateText) =>
                                formatChangeText(CHANGES.DONE, displayName, dateText, completedAt)
                            }
                        />
                    ) : null}
                    <AppointmentResponsibles
                        taskResponsibles={task?.responsibles}
                        cardResponsibles={card?.responsibles}
                    />
                </Grid>
            </Grid>
        </>
    );
};

CalendarDetail.propTypes = {};

export default memo(CalendarDetail);
