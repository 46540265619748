import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFieldFast } from '../../../../modules/form/hooks';
import TypedAutocomplete from '../../base/autocomplete/TypedAutocomplete';
import { autocompleteResources } from '../../../../modules/resources/resourcesSlice';
import { RESOURCE_RESOURCE } from '../../../../modules/api/resources';

const ResourceAutocomplete = ({
    name,
    label,
    fullWidth,
    contrast,
    size,
    fetchParams,
    getOptionDisabled,
    ...other
}) => {
    const { t } = useTranslation();
    const [{ value }, , { setValue }] = useFieldFast(name);

    const handleFetch = ({ q }) => {
        const { q: preQ, ...params } = fetchParams;
        return autocompleteResources({ ...params, q: preQ ? `${preQ}${q}` : q });
    };

    const handleChange = value => {
        setValue(value);
    };

    return (
        <TypedAutocomplete
            name={name}
            fetchAction={handleFetch}
            value={value}
            onChange={handleChange}
            label={label || t('components.ResourceAutocomplete.title')}
            fullWidth={fullWidth}
            contrast={contrast}
            size={size}
            noOptionsText={t('components.ResourceAutocomplete.noOptions')}
            expectedType={RESOURCE_RESOURCE}
            getOptionDisabled={getOptionDisabled}
            disablePortal
            {...other}
        />
    );
};

ResourceAutocomplete.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    contrast: PropTypes.bool,
    fullWidth: PropTypes.bool,
    size: PropTypes.string,
    fetchParams: PropTypes.shape({}),
    getOptionDisabled: PropTypes.func,
};

ResourceAutocomplete.defaultProps = {
    label: null,
    contrast: false,
    fullWidth: false,
    size: 'medium',
    fetchParams: {},
    getOptionDisabled: null,
};

export default ResourceAutocomplete;
