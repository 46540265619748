import React from 'react';
import * as PropTypes from 'prop-types';
import { flexRender } from 'material-react-table';
import ResourceTablePrintCell from './ResourceTablePrintCell';
import { toAbsolutePath } from '../../modules/files/utils';
import { useSelector } from 'react-redux';
import { selectSettingsByKey } from '../../modules/settings/selectors';
import { LOGO_DEFAULT_SETTINGS, SITE_NAME_SETTINGS } from '../../modules/settings/config';
import { useTheme } from '@mui/styles';

const checkColumnIncluded = (id, ignoredColumns = []) =>
    !(id && ['mrt-row-select', 'mrt-row-actions', ...ignoredColumns].includes(id));

const ResourceTablePrint = ({
    table,
    columns,
    label,
    ignoredColumns,
    renderHead,
    renderFooterRow,
    renderFooter,
}) => {
    const theme = useTheme();
    const logoPath = useSelector(state => selectSettingsByKey(state, LOGO_DEFAULT_SETTINGS));
    const siteName = useSelector(state => selectSettingsByKey(state, SITE_NAME_SETTINGS));

    return (
        <div>
            {label ? (
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        <h3>{label}</h3>
                        {typeof renderHead === 'function' ? renderHead(table) : null}
                    </div>
                    <div
                        style={{
                            flex: 0,
                            paddingTop: 12,
                            paddingBottom: 12,
                        }}
                    >
                        <img
                            src={toAbsolutePath(logoPath)}
                            alt={`Logo ${siteName}`}
                            style={{
                                maxWidth: 300,
                                minHeight: 56,
                                padding: 10,
                                backgroundColor: theme.palette.primary.main,
                            }}
                        />
                    </div>
                </div>
            ) : null}
            <table aria-label={label}>
                <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id} style={{ height: 32 }}>
                            {headerGroup.headers.map(header =>
                                checkColumnIncluded(header?.id, ignoredColumns) ? (
                                    <th key={header.id}>
                                        <div
                                            style={{
                                                padding: 4,
                                                fontWeight: 'bold',
                                                textWrap: 'balance',
                                                ...(typeof header?.column?.columnDef
                                                    ?.forcePrintStyle === 'object'
                                                    ? header.column.columnDef.forcePrintStyle
                                                    : {}),
                                            }}
                                            color="primary"
                                        >
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                      header.column.columnDef.Header ??
                                                          header.column.columnDef.header,
                                                      header.getContext()
                                                  )}
                                        </div>
                                    </th>
                                ) : null
                            )}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map(row =>
                        row?.id ? (
                            <tr key={row.id}>
                                {row.getVisibleCells().map((cell, _columnIndex) =>
                                    checkColumnIncluded(cell?.column?.id, ignoredColumns) ? (
                                        <td key={cell.id}>
                                            <div
                                                style={{
                                                    padding: 4,
                                                    fontSize: 14,
                                                    textWrap: 'balance',
                                                    ...(typeof cell?.column?.columnDef
                                                        ?.forcePrintStyle === 'object'
                                                        ? cell.column.columnDef.forcePrintStyle
                                                        : {}),
                                                }}
                                            >
                                                <ResourceTablePrintCell
                                                    columns={columns}
                                                    table={table}
                                                    cell={cell}
                                                />
                                            </div>
                                        </td>
                                    ) : null
                                )}
                            </tr>
                        ) : null
                    )}
                    {typeof renderFooterRow === 'function'
                        ? renderFooterRow(table, columnId =>
                              checkColumnIncluded(columnId, ignoredColumns)
                          )
                        : null}
                </tbody>
            </table>
            {typeof renderFooter === 'function' ? renderFooter(table) : null}
        </div>
    );
};

ResourceTablePrint.propTypes = {
    table: PropTypes.shape({}).isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    label: PropTypes.string,
    ignoredColumns: PropTypes.arrayOf(PropTypes.string),
    renderHead: PropTypes.func,
    renderFooterRow: PropTypes.func,
    renderFooter: PropTypes.func,
};

ResourceTablePrint.defaultProps = {
    label: null,
    ignoredColumns: [],
    renderHead: null,
    renderFooterRow: null,
    renderFooter: null,
};

export default ResourceTablePrint;
