import {
    EMPLOYEE_RESOURCE,
    GROUP_RESOURCE,
    RESOURCE_RESOURCE,
    TASK_RESOURCE,
} from '../api/resources';
import { getColor } from '../icons/avatars';

/* export const CALENDAR_RESOURCE_MODELS = {
    [RESOURCE_RESOURCE]: 'App\\Models\\Resource',
    [EMPLOYEE_RESOURCE]: 'App\\Models\\Employee',
    [GROUP_RESOURCE]: 'App\\Models\\Group',
    [TASK_RESOURCE]: 'App\\Models\\Task',
}; */

/* export const getResourceKeyFromModel = model => {
    const keyModelPair = Object.entries(CALENDAR_RESOURCE_MODELS).find(
        ([_, listModel]) => model === listModel
    );

    return keyModelPair ? keyModelPair[0] : null;
}; */

export const formatResourceGroupConfig = (resourceConfig, keyName = null, onFilter) => {
    const key = keyName ?? 'group';

    if (resourceConfig && Array.isArray(resourceConfig)) {
        return resourceConfig.reduce(
            (carry, config) => {
                if (!config || !config[key] || (onFilter && onFilter(config))) {
                    return carry;
                }

                return {
                    groupKeys: [...carry.groupKeys, config[key]],
                    groupConfig: {
                        ...carry.groupConfig,
                        [config[key]]: config,
                    },
                };
            },
            { groupKeys: [], groupConfig: {} }
        );
    }
    return { groupKeys: [], groupConfig: {} };
};

export const formatEmployee = employee => {
    if (!employee) {
        return null;
    }

    const { id, person, user } = employee;

    const getDisplayName = () => {
        if (user && user.display_name) {
            return user.display_name;
        }

        if (person && person.display_name) {
            return person.display_name;
        }

        return `Employee ${id}`;
    };

    const getShort = () => {
        if (user && user.display_name_short) {
            return user.display_name_short;
        }

        const displayName = getDisplayName();

        if (displayName) {
            return displayName.slice(0, 3);
        }

        return '';
    };

    const getAvatar = () => {
        if (user && user.avatar) {
            return user.avatar;
        }
    };

    const text = getDisplayName();
    const short = getShort();
    const color = getColor(user?.display_name || user?.display_name_short, user?.color);
    const avatar = getAvatar();

    return {
        id,
        key: `resourceGroupPickerEmployee.${id}`,
        flatKey: formatFlatResourceKey(id, EMPLOYEE_RESOURCE).key,
        text,
        short,
        icon: null,
        avatar,
        color,
    };
};

export const formatResource = resource => {
    if (!resource) {
        return null;
    }

    const { id, display_name, color, icon } = resource;

    return {
        id,
        key: `resourceGroupPickerResource.${id}`,
        flatKey: formatFlatResourceKey(id, RESOURCE_RESOURCE).key,
        text: display_name,
        short: null,
        color: getColor(display_name, color),
        icon,
        avatar: null,
    };
};

export const formatFlatResourceKey = (resourceId, resourceType) => ({
    key: `${resourceType}.${resourceId}`,
    id: resourceId,
    resource: resourceType,
});
