import React from 'react';
import * as PropTypes from 'prop-types';
import { formatDurationText } from '../../modules/datetime/utils';
import { Typography } from '@mui/material';

const Duration = ({ durationValue, ...other }) => {
    return <Typography {...other}>{formatDurationText(durationValue)}</Typography>;
};

Duration.propTypes = {
    durationValue: PropTypes.number,
};

export default Duration;
