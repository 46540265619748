import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import DirectionsOutlined from '@mui/icons-material/DirectionsOutlined';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClickAwayListener, Tooltip } from '@mui/material';
import { AddressPropType } from '../../modules/addresses/proptypes';
import TextField from '../form/base/TextField';
import IconButton from '../form/base/IconButton';
import LoadingIcon from '../loading/LoadingIcon';
import Confirmation from '../form/Confirmation';

const useStyles = makeStyles(theme => ({
    title: {
        hyphens: 'auto',
    },
    icon: {
        opacity: 0.5,
        height: 24,
    },
    editable: {
        cursor: 'pointer',
        border: `1px solid ${theme.palette.grey['400']}`,
        borderRadius: 4,
        padding: '5px 15px',
    },
    empty: {
        color: theme.palette.text.secondary,
        fontStyle: 'italic',
    },
}));

const CardAddress = ({ address, asTitle, short, noIcon, isDirty, onEdit, onRemove }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        if (isDirty && address.full !== ' ') {
            setEditing(true);
        }
        if (!isDirty) {
            setEditing(false);
        }
    }, [address, isDirty]);

    const handleRemove = () => {
        setLoading(true);
        onRemove().then(() => setLoading(false));
    };

    if (short) return address.short === '' ? t('components.Map.empty') : address.short;

    return asTitle ? (
        <Tooltip title={t('components.Card.address')} disableInteractive>
            <Typography variant="h3" component="p" className={classes.title}>
                {address.short === '' ? t('components.Map.empty') : address.short}
            </Typography>
        </Tooltip>
    ) : (
        <Grid
            data-test-id="toggle-address-form"
            container
            alignItems="center"
            spacing={1}
            ref={ref}
        >
            {!noIcon && (
                <Grid item>
                    <Box className={classes.icon}>
                        <DirectionsOutlined />
                    </Box>
                </Grid>
            )}
            <Grid item>
                <Tooltip title={t('components.Card.address')} disableInteractive>
                    {onEdit && editing ? (
                        <ClickAwayListener
                            onClickAway={() => {
                                if (!isDirty) {
                                    setEditing(false);
                                }
                            }}
                        >
                            <Grid container spacing={1}>
                                <Grid item>
                                    <TextField
                                        data-test-id="address-street-input"
                                        label="Straße und Hausnummer"
                                        value={address.address1}
                                        onChange={e => onEdit('address1', e.target.value)}
                                        size="small"
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label="Zusatz"
                                        value={address.address2}
                                        placeholder="zwischen den Pfeilern"
                                        onChange={e => onEdit('address2', e.target.value)}
                                        size="small"
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={t('components.Map.zip')}
                                        value={address.zip}
                                        onChange={e => onEdit('zip', e.target.value)}
                                        size="small"
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={t('components.Map.city')}
                                        value={address.city}
                                        onChange={e => onEdit('city', e.target.value)}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                        </ClickAwayListener>
                    ) : (
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item>
                                <Typography
                                    variant="body2"
                                    onClick={() => setEditing(true)}
                                    className={classNames({
                                        [classes.editable]: onEdit,
                                        [classes.empty]: address.full === ' ',
                                    })}
                                >
                                    {address.full === ' '
                                        ? t('components.Map.empty')
                                        : address.full}
                                </Typography>
                            </Grid>
                            {onRemove && !isDirty && address.full !== ' ' && (
                                <Grid item style={{ position: 'relative' }}>
                                    <Tooltip title={t('components.Map.clear')} disableInteractive>
                                        <LoadingIcon isLoading={loading}>
                                            <Confirmation
                                                popupId="ResetCardLocation"
                                                onConfirm={handleRemove}
                                                icon={<LocationOffIcon color="primary" />}
                                                label={t('components.Map.clearConfirm')}
                                            >
                                                <IconButton size="tiny">
                                                    <LocationOffIcon color="primary" />
                                                </IconButton>
                                            </Confirmation>
                                        </LoadingIcon>
                                    </Tooltip>
                                </Grid>
                            )}
                        </Grid>
                    )}
                </Tooltip>
            </Grid>
        </Grid>
    );
};

CardAddress.propTypes = {
    address: AddressPropType.isRequired,
    asTitle: PropTypes.bool,
    short: PropTypes.bool,
    noIcon: PropTypes.bool,
    isDirty: PropTypes.bool,
    onEdit: PropTypes.func,
    onRemove: PropTypes.func,
};

CardAddress.defaultProps = {
    asTitle: false,
    short: false,
    noIcon: false,
    isDirty: false,
    onEdit: null,
    onRemove: null,
};

export default CardAddress;
