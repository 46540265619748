import React, { useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import classNames from 'classnames';
import { Icon } from '@iconify/react';
import crownF from '@iconify/icons-jam/crown-f';
import { Avatar as MuiAvatar, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import Popper from '@mui/material/Popper';
import { bindHover, bindPopper, usePopupState } from 'material-ui-popup-state/hooks';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import AvatarDestroyIntent from './AvatarDestroyIntent';
import { ASSIGNED } from '../../modules/responsibles/types';
import { getColor } from '../../modules/icons/avatars';
import { ConditionalWrapper } from '../utils/ConditionalWrapper';

const useStyles = makeStyles(theme => ({
    root: {
        transition: theme.transitions.create(['margin'], {
            duration: theme.transitions.duration.shorter,
        }),
    },

    rootHovered: {
        marginTop: '-4px',
    },

    avatarContainer: {
        zIndex: 0,
        position: 'relative',

        '&:hover $deleteContainer': {
            opacity: 1,
        },
    },

    avatarBase: {
        transition: '.2s',
    },

    crown: {
        position: 'absolute',
        left: '50%',
        marginLeft: -6,
        marginTop: -6,
        color: '#FFD500',
        zIndex: 1,
    },

    medium: {
        fontSize: 16,
    },

    small: {
        height: 32,
        width: 32,
        fontSize: 13,
    },

    tiny: {
        width: 22,
        height: 22,
        fontSize: 8,
    },

    pointer: {
        // cursor: 'pointer',
    },

    name: {
        marginBottom: 0,
    },

    border: {
        border: `1px solid ${theme.palette.primary.contrastText}`,
    },

    // deleted: { backgroundColor: grey['100'] },

    primary: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.contrastText,
    },

    hoverContainer: {
        zIndex: 1301, // https://github.com/mui-org/material-ui/issues/18905
    },

    hoverArrow: {
        width: 10,
        position: 'absolute',
        marginLeft: -2,

        '&:after': {
            content: '""',
            width: '0',
            height: '0',
            position: 'absolute',
            border: 'solid transparent',
            left: '50%',
            borderColor: 'rgba(0,0,0,0)',
            borderBottomColor: grey['100'],
            top: -5,
            borderWidth: 8,
            marginLeft: -8,
        },
        '&:before': {
            content: '""',
            width: '0',
            height: '0',
            position: 'absolute',
            border: 'solid transparent',
            left: '50%',
            borderColor: 'rgba(0,0,0,0)',
            borderBottomColor: grey['300'],
            top: -7,
            borderWidth: 9,
            marginLeft: -9,
        },
    },

    hoverInner: {
        border: '1px solid',
        borderColor: grey['300'],
        backgroundColor: grey['100'],
        marginTop: 10,
        // color: theme.palette.primary.main,
    },

    hoverExtra: {
        backgroundColor: theme.palette.background.paper,
    },

    divider: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },

    deleteContainer: {
        zIndex: 1,
        opacity: 0,
        transition: 'opacity 0.1s ease-in',
        position: 'absolute',
        right: -5,
        top: -5,
        width: 16,
        height: 16,
        borderRadius: 20,
        backgroundColor: theme.palette.primary.contrastText,
    },
    deleteIcon: {
        zIndex: 0,
        width: '100%',
        height: '100%',
        position: 'absolute',
        left: 0,
        top: 0,

        '&:active + $deleting': {
            backgroundPosition: 'left bottom',
            transition: 'background-position 1s linear',
        },
    },
    deleting: {
        zIndex: 1,
        width: '100%',
        height: '100%',
        position: 'absolute',
        left: 0,
        top: 0,
        background: 'linear-gradient(to right, red 50%, transparent 50%)',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom',
        opacity: 0.6,
        borderRadius: 20,
        pointerEvents: 'none',
    },
    deleteInfo: {
        whiteSpace: 'nowrap',
    },

    inline: {
        display: 'inline-flex',
        alignItems: 'center',
        width: 'auto',
    },

    disabled: {
        backgroundColor: theme.palette.grey[400],
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: theme.palette.grey[400],
    },

    disabledOutlined: {
        backgroundColor: 'transparent',
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: theme.palette.grey[400],
        color: theme.palette.grey[400],
    },
}));

const BaseAvatar = ({
    path,
    name,
    displayName,
    shortName,
    extra,
    withBorder,
    disableHover,
    small,
    tiny,
    crown,
    type,
    hoverExtra,
    disablePortal,
    inline,
    fixedPosition,
    color: forceColor,
    style,
    outlined,
    outlinedBackgroundColor,
    disabled,
    removeLabel,
    confirmRemoveLabel,
    RemoveIcon,
    onDelete,
    label,
    popperPlacement,
    ...other
}) => {
    const classes = useStyles();
    const [arrowElement, setArrowElement] = useState(null);
    const [keepOpen, setKeepOpen] = useState(false);

    const popupState = usePopupState({
        variant: 'popper',
        popupId: 'popper',
    });

    const hoverProps = !disableHover && !keepOpen ? bindHover(popupState) : {};
    const color = getColor(displayName, forceColor);

    useEffect(() => {
        const clickOutside = event => {
            if (
                keepOpen &&
                popupState.isOpen &&
                !document.querySelector('#popper').contains(event.target)
            ) {
                setKeepOpen(false);
                popupState.close();
            }
        };

        if (keepOpen) {
            window.addEventListener('mousedown', clickOutside);
        }
        return () => {
            window.removeEventListener('mousedown', clickOutside);
        };
    }, [keepOpen, popupState]);

    const popperProps = useMemo(() => {
        const bound = bindPopper(popupState);

        if (keepOpen) {
            return {
                ...bound,
                onMouseLeave: () => null,
            };
        }

        return bound;
    }, [keepOpen, popupState, bindPopper]);

    return (
        <ConditionalWrapper
            condition={label}
            wrapper={_children => (
                <Tooltip title={label} placement="left" disableInteractive>
                    {_children}
                </Tooltip>
            )}
        >
            <Grid
                container
                spacing={name || extra ? 1 : 0}
                className={classNames({
                    [classes.root]: true,
                    [classes.pointer]: !disableHover,
                    [classes.rootHovered]: !fixedPosition && popupState.isOpen,
                    [classes.inline]: inline,
                })}
                {...hoverProps}
            >
                <Grid item className={classes.avatarContainer}>
                    {crown && (
                        <Icon
                            icon={crownF}
                            className={classes.crown}
                            style={{ fontSize: '12px' }}
                        />
                    )}
                    <MuiAvatar
                        src={path}
                        alt="Avatar"
                        style={{
                            ...(color !== 'primary' && !disabled
                                ? {
                                      backgroundColor: outlined
                                          ? outlinedBackgroundColor || 'transparent'
                                          : color,
                                      border: `${tiny ? 1 : 2}px solid ${color}`,
                                      ...(outlined ? { color } : {}),
                                  }
                                : {}),
                            ...style,
                        }}
                        className={classNames({
                            [classes.avatarBase]: true,
                            [classes.disabled]: disabled && !outlined,
                            [classes.disabledOutlined]: disabled && outlined,
                            [classes.border]: withBorder,
                            [classes.medium]: !small,
                            [classes.small]: small,
                            [classes.tiny]: tiny,
                            [classes.primary]: color === 'primary',
                        })}
                        imgProps={{
                            style: disabled
                                ? {
                                      filter: 'grayscale(100%) brightness(150%)',
                                      opacity: 0.6,
                                      transition: '.2s',
                                  }
                                : {
                                      filter: null,
                                      opacity: 1,
                                      transition: '.2s',
                                  },
                        }}
                        {...other}
                    >
                        {shortName}
                    </MuiAvatar>
                </Grid>
                {name && (
                    <Grid item>
                        <Typography
                            variant="h3"
                            component="p"
                            color="primary"
                            className={classes.name}
                        >
                            {displayName}
                        </Typography>
                    </Grid>
                )}
                {extra && (
                    <Grid item>
                        <Typography variant="subtitle1" component="p" color="textSecondary">
                            {extra}
                        </Typography>
                    </Grid>
                )}
                {!disableHover && (
                    <Popper
                        className={classes.hoverContainer}
                        {...popperProps}
                        placement={popperPlacement}
                        modifiers={[
                            {
                                name: 'flip',
                                enabled: true,
                            },
                            {
                                name: 'preventOverflow',
                                enabled: true,
                                options: {
                                    boundariesElement: 'scrollParent',
                                    padding: 5,
                                },
                            },
                            {
                                name: 'arrow',
                                enabled: true,
                                options: {
                                    element: arrowElement,
                                },
                            },
                        ]}
                        disablePortal={disablePortal}
                    >
                        <span className={classes.hoverArrow} ref={setArrowElement} />
                        <Paper className={classes.hoverInner} elevation={3}>
                            <Typography
                                data-test-id="taskResponsibleHoverText"
                                variant="subtitle2"
                                align="center"
                                style={{ padding: '2px 20px', fontWeight: 'bold' }}
                            >
                                {displayName}
                            </Typography>
                            {/* {onDelete && <Divider className={classes.divider} />} */}
                            <Box className={classes.hoverExtra}>
                                {hoverExtra}
                                {onDelete && (
                                    <AvatarDestroyIntent
                                        type={type}
                                        label={removeLabel}
                                        confirmLabel={confirmRemoveLabel}
                                        Icon={RemoveIcon}
                                        onConfirmed={onDelete}
                                        onConfirm={confirm => {
                                            setKeepOpen(confirm);
                                            if (!confirm) {
                                                popupState.close();
                                            }
                                        }}
                                    />
                                )}
                            </Box>
                        </Paper>
                    </Popper>
                )}
            </Grid>
        </ConditionalWrapper>
    );
};

BaseAvatar.propTypes = {
    path: PropTypes.string,
    name: PropTypes.bool,
    displayName: PropTypes.string,
    shortName: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    extra: PropTypes.string,
    withBorder: PropTypes.bool,
    disableHover: PropTypes.bool,
    small: PropTypes.bool,
    tiny: PropTypes.bool,
    crown: PropTypes.bool,
    hoverExtra: PropTypes.node,
    disablePortal: PropTypes.bool,
    inline: PropTypes.bool,
    fixedPosition: PropTypes.bool,
    type: PropTypes.string,
    style: PropTypes.shape({}),
    outlined: PropTypes.bool,
    outlinedBackgroundColor: PropTypes.string,
    disabled: PropTypes.bool,
    removeLabel: PropTypes.string,
    confirmRemoveLabel: PropTypes.string,
    RemoveIcon: PropTypes.elementType,
    onDelete: PropTypes.func,
    label: PropTypes.string,
    popperPlacement: PropTypes.string,
};

BaseAvatar.defaultProps = {
    path: undefined,
    name: false,
    displayName: 'unknown',
    shortName: null,
    extra: null,
    withBorder: false,
    disableHover: false,
    small: false,
    tiny: false,
    crown: false,
    hoverExtra: null,
    disablePortal: false,
    inline: false,
    fixedPosition: false,
    type: ASSIGNED,
    style: null,
    outlined: false,
    outlinedBackgroundColor: null,
    disabled: false,
    removeLabel: null,
    confirmRemoveLabel: null,
    RemoveIcon: null,
    onDelete: null,
    label: null,
    popperPlacement: 'bottom',
};

export default BaseAvatar;
