import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { EMPLOYEE_RESOURCE, RESOURCE_RESOURCE } from '../../modules/api/resources';
import { debounce } from 'lodash/function';
import { CALENDAR_RESOURCE_MODELS } from '../../modules/calendar/utils';
import { IdPropType } from '../../modules/proptypes';

const AppointmentCalendarContext = React.createContext(null);

export const useAppointmentCalendar = () => React.useContext(AppointmentCalendarContext);

export const APPOINTMENT_CALENDAR_CONTEXT_FUNC_TYPE = {
    VALUE: 'value',
    COMMIT: 'commit',
    DELETE: 'delete',
};

const AppointmentCalendarProvider = ({
    calendarId,
    defaultEntityResource,
    defaultEntityId,
    overrideFetchKey,
    forceCreateValues,
    showColor,
    maxDailyAppointmentsPerRow,
    deniedPeriods,
    onResourcesChange,
    children,
}) => {
    const [pendingCommit, setPendingCommit] = useState(false);
    const handleValues = useRef();
    const handleCommit = useRef();
    const handleRemove = useRef();

    const [selectedBasicResources, setSelectedResources] = useState(
        defaultEntityResource === RESOURCE_RESOURCE ? [defaultEntityId] : []
    );
    const [selectedBasicEmployees, setSelectedEmployees] = useState(
        defaultEntityResource === EMPLOYEE_RESOURCE ? [defaultEntityId] : []
    );
    const [selectedGroupResources, setSelectedGroupResources] = useState([]);
    const [selectedGroupEmployees, setSelectedGroupEmployees] = useState([]);
    const selectedResources = useMemo(() => {
        const allIds = [...selectedBasicResources, ...selectedGroupResources];
        return allIds.filter((id, index) => allIds.indexOf(id) === index);
    }, [selectedBasicResources, selectedGroupResources]);
    const selectedEmployees = useMemo(() => {
        const allIds = [...selectedBasicEmployees, ...selectedGroupEmployees];
        return allIds.filter((id, index) => allIds.indexOf(id) === index);
    }, [selectedBasicEmployees, selectedGroupEmployees]);

    const debouncedResourcesChange = useMemo(
        () => debounce(values => onResourcesChange(values), 60),
        [onResourcesChange]
    );

    const attachFunc = (type, func) => {
        if (type === APPOINTMENT_CALENDAR_CONTEXT_FUNC_TYPE.VALUE) {
            handleValues.current = func;
        }

        if (type === APPOINTMENT_CALENDAR_CONTEXT_FUNC_TYPE.COMMIT) {
            handleCommit.current = func;
        }

        if (type === APPOINTMENT_CALENDAR_CONTEXT_FUNC_TYPE.DELETE) {
            handleRemove.current = func;
        }
    };

    const handleDelete = params => {
        if (typeof handleRemove.current === 'function') {
            return handleRemove.current(params);
        }

        return false;
    };

    const handleSubmit = values => {
        if (typeof handleValues.current === 'function') {
            handleValues.current(values);
            setPendingCommit(true);
        }

        return false;
    };

    useEffect(() => {
        if (pendingCommit && typeof handleCommit.current === 'function') {
            setPendingCommit(false);
            return handleCommit.current();
        }
    }, [pendingCommit]);

    useEffect(() => {
        debouncedResourcesChange([
            ...selectedResources.map(id => ({
                id,
                type: CALENDAR_RESOURCE_MODELS[RESOURCE_RESOURCE],
            })),
            ...selectedEmployees.map(id => ({
                id,
                type: CALENDAR_RESOURCE_MODELS[EMPLOYEE_RESOURCE],
            })),
        ]);
    }, [selectedEmployees, selectedResources]);

    const values = useMemo(
        () => ({
            calendarId,
            defaultEntityResource,
            defaultEntityId,
            forceCreateValues,
            overrideFetchKey,
            showColor,
            maxDailyAppointmentsPerRow,
            deniedPeriods,
            selectedResources,
            selectedEmployees,
            selectedGroupResources,
            selectedGroupEmployees,
            setSelectedResources,
            setSelectedEmployees,
            setSelectedGroupResources,
            setSelectedGroupEmployees,
            attachFunc,
            handleSubmit,
            handleDelete,
        }),
        [
            calendarId,
            defaultEntityResource,
            defaultEntityId,
            forceCreateValues,
            overrideFetchKey,
            showColor,
            maxDailyAppointmentsPerRow,
            deniedPeriods,
            selectedResources,
            selectedEmployees,
            selectedGroupResources,
            selectedGroupEmployees,
            setSelectedResources,
            setSelectedEmployees,
            setSelectedGroupResources,
            setSelectedGroupEmployees,
            attachFunc,
            handleSubmit,
            handleDelete,
        ]
    );

    return (
        <AppointmentCalendarContext.Provider value={values}>
            {children}
        </AppointmentCalendarContext.Provider>
    );
};

AppointmentCalendarProvider.propTypes = {
    calendarId: PropTypes.string.isRequired,
    defaultEntityId: PropTypes.oneOfType([IdPropType, PropTypes.string]),
    defaultEntityResource: PropTypes.oneOf([EMPLOYEE_RESOURCE, RESOURCE_RESOURCE]),
    forceCreateValues: PropTypes.shape({}),
    overrideFetchKey: PropTypes.string,
    showColor: PropTypes.bool,
    onResourcesChange: PropTypes.func.isRequired,
};

AppointmentCalendarProvider.defaultProps = {
    defaultEntityId: null,
    defaultEntityResource: null,
    forceCreateValues: {},
    overrideFetchKey: null,
    showColor: false,
};

export default memo(AppointmentCalendarProvider);
