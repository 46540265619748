import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCallbackFunc } from '../hooks';
import { filterList, indexPage } from './actions';
import { clearList, initList, saveOrderBy } from './listsSlice';
import {
    selectListData,
    selectListDataById,
    selectListDataIds,
    selectListIsInitialized,
    selectListIsLoading,
    selectListItemsTotal,
    selectListOrderBy,
    selectListPage,
    selectListPageCount,
} from './selectors';
import { makeRelatedDataSelector } from '../redux/resource/selectors';
import { applyToNestedObject, getFromNestedObject } from './utils';

export const useResourceList = ({
    listId,
    resource,
    fetchParams,
    staticParams,
    contexts,
    initialOrderBy,
    initialSearch,
    autoload,
    autoInitialize,
    limit = 25,
    continuous,
    index,
    with: withConfig,
    updateWatch,
    criteria,
    unique = false,
}) => {
    const dispatch = useDispatch();
    const dataIds = useSelector(state => selectListDataIds(state, listId));

    /* this is true if the list was loaded at least once - NOT if initList was called or not! */
    const initialized = useSelector(state => selectListIsInitialized(state, listId));

    const loading = useSelector(state => selectListIsLoading(state, listId));
    const page = useSelector(state => selectListPage(state, listId));
    const pageCount = useSelector(state => selectListPageCount(state, listId));
    const itemsTotal = useSelector(state => selectListItemsTotal(state, listId));
    const orderBy = useSelector(state => selectListOrderBy(state, listId));

    const stableWith = useRef(withConfig);
    const stableStatic = useRef(staticParams);
    const stableContexts = useRef(contexts);

    const dataSelector = useMemo(
        () => (state, dataId) => selectListDataById(state, listId, dataId),
        [listId]
    );

    const fullSelector = useMemo(() => state => selectListData(state, listId, true), [listId]);

    const relatedSelector = useMemo(() => makeRelatedDataSelector(withConfig), [withConfig]);

    const handlePage = useCallbackFunc((event, newPage, force = false) => {
        if (initialized || force) {
            return dispatch(
                indexPage(listId, newPage, fetchParams, null, (force && !initialSearch) || index)
            );
        }

        return false;
    });

    const handleNextPage = useCallbackFunc(() => {
        return handlePage(null, page + 1);
    });

    const handleOrderBy = useCallbackFunc(newOrderBy => {
        if (initialized) {
            dispatch(saveOrderBy(newOrderBy, { listId }));
            handlePage(null, 1);
        }
    });

    const handleFetch = useCallbackFunc(params => {
        return dispatch(indexPage(listId, 1, { ...fetchParams, ...params }, null, true));
    });

    const handleSearch = useCallbackFunc(search => {
        if (initialized || (!autoload && !autoInitialize)) {
            return dispatch(filterList(listId, search, orderBy, fetchParams, null, index));
        }
        return Promise.resolve();
    });

    const handleClear = useCallbackFunc(() => dispatch(clearList({ listId })));

    const stableCriteria = useRef(criteria);

    useEffect(() => {
        if (!initialized) {
            dispatch(
                initList({
                    listId,
                    resource,
                    orderBy: initialOrderBy,
                    search: initialSearch,
                    continuous,
                    criteria: stableCriteria.current,
                    staticParams: {
                        limit,
                        ...stableStatic.current,
                        with: stableWith.current,
                        contexts: stableContexts.current,
                    },
                    unique,
                })
            );

            if (!loading && autoload) {
                handlePage(null, 1, true);
            }
        }
    }, [
        dispatch,
        initialized,
        listId,
        resource,
        initialOrderBy,
        continuous,
        autoload,
        handlePage,
        loading,
        initialSearch,
        stableCriteria,
        stableStatic,
        stableWith,
        stableContexts,
        limit,
    ]);

    useEffect(() => {
        if (autoload) {
            handlePage(null, 1);
        }
    }, [listId, fetchParams, contexts, autoload, handlePage]);

    useEffect(() => {
        if (autoInitialize && !initialized) {
            handlePage(null, 1, true);
        }
    }, [autoInitialize, initialized, handlePage]);

    useEffect(() => {
        if (updateWatch) {
            handlePage(null, 1, true);
        }
    }, [updateWatch, handlePage]);

    return useMemo(
        () => ({
            dataIds,
            dataSelector,
            fullSelector,
            relatedSelector,
            loading,
            initialized,
            page,
            pageCount,
            itemsTotal,
            orderBy,
            handlePage,
            handleNextPage,
            handleFetch,
            handleSearch,
            handleClear,
            handleOrderBy,
        }),
        [
            dataIds,
            dataSelector,
            fullSelector,
            relatedSelector,
            loading,
            initialized,
            page,
            pageCount,
            itemsTotal,
            orderBy,
            handlePage,
            handleNextPage,
            handleFetch,
            handleSearch,
            handleClear,
            handleOrderBy,
        ]
    );
};

/**
 * Merge Nested Models to a List
 * @param list given list without nested models
 * @param subSelectors array of selector configs (i.e. { model: 'task', selector: listByIdSelectorFunction(), path: 'dot.notated.nested' *fallback:nestedOn1stLayer, identifier: 'task_id' *fallback:<model>_id, idNested: true *fallback:false })
 * @return merged given list with merged nested models
 */
export const useResourceListNestedSelects = ({ list = [], nestedSelectors = [] }) => {
    const configWithValues = nestedSelectors.map(({ selector, ...config }) => {
        const valuesById = useSelector(selector);

        return {
            valuesById,
            ...config,
        };
    });

    return useMemo(
        () =>
            list.map(item => {
                if (item) {
                    const newProps = configWithValues.reduce(
                        (
                            carry,
                            { model, valuesById, path = null, identifier = null, idNested = false }
                        ) => {
                            const identifierOptions = [`${model}_id`, `${model}_by`];
                            const finalIdentifier =
                                identifier || identifierOptions.find(ident => ident in item);
                            const id = item[finalIdentifier];

                            if (path) {
                                //ToDo: Deep nested still WIP untested
                                const currentMergedProps = { ...carry, ...item };
                                const nestedSteps = path.split('.');

                                const { layer, missingSteps } = getFromNestedObject(
                                    nestedSteps,
                                    currentMergedProps
                                );

                                const nestedId =
                                    idNested && finalIdentifier in layer
                                        ? layer[finalIdentifier]
                                        : id;

                                if (nestedId in valuesById) {
                                    const layers = applyToNestedObject(
                                        nestedSteps,
                                        carry,
                                        { ...layer, [model]: valuesById[nestedId] },
                                        missingSteps
                                    );

                                    return {
                                        ...carry,
                                        ...layers,
                                    };
                                }

                                return carry;
                            }

                            if (id in valuesById) {
                                const value = valuesById[id];

                                return {
                                    ...carry,
                                    [model]: value,
                                };
                            }

                            return carry;
                        },
                        {}
                    );

                    return {
                        ...item,
                        ...newProps,
                    };
                }

                return {};
            }),
        [list, configWithValues]
    );
};

export const useAutoPaginate = ({ active = true, handlePage = null, delay = 128 }) => {
    const handlePaginate = (page = 1) => {
        handlePage(null, page, true).then(({ meta }) => {
            if (typeof meta === 'object') {
                const { current_page, last_page } = meta;

                if (current_page < last_page) {
                    if (typeof delay === 'number') {
                        setTimeout(() => {
                            handlePaginate(current_page + 1);
                        }, delay);
                    } else {
                        handlePaginate(current_page + 1);
                    }
                }
            }
        });
    };

    useEffect(() => {
        if (active && typeof handlePage === 'function') {
            handlePaginate();
        }
    }, [active, handlePage]);
};

export const useOrderedFullSelect = (dataIds, fullSelector) => {
    const itemsById = useSelector(fullSelector);

    return useMemo(() => {
        if (Array.isArray(dataIds) && dataIds.length && typeof itemsById === 'object') {
            /*return dataIds ToDo: fix sort in resource list state
                .reduce((carry, id) => {
                    if (id in itemsById) {
                        return [...carry, itemsById[id]];
                    }

                    return carry;
                }, [])*/
            return Object.values(itemsById).sort((a, b) => a.order - b.order);
        }

        return [];
    }, [dataIds, itemsById]);
};
