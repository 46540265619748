import axios from 'axios';
import qs from 'qs';

import { bulkFulfilledAction, errorAction, pendingAction } from './actions';
import { methods } from './resources';
import Sequentializer from './sequentializer';
import { apiError } from './apiSlice';
import { combineWith } from '../redux/resource/utils';

const checkParamsBulk = (params, ignoreId = false) => {
    const isArray = params && Array.isArray(params) && params.length;
    const isMultiId = params?.id && Array.isArray(params.id) && params.id.length;
    return ignoreId ? isArray : isArray || isMultiId;
};

const getEndpointUrl = (endpoint, action, params) => {
    if (
        ['show', 'update', 'destroy', 'restore'].includes(action) &&
        !(params?.id || Array.isArray(params))
    ) {
        throw new Error(`${action} needs an id in params`);
    }

    if (['show', 'update', 'destroy'].includes(action)) {
        if (checkParamsBulk(params)) {
            return `${endpoint}/`;
        }

        return `${endpoint}/${params.id}/`;
    }

    if (action === 'restore') {
        return `${endpoint}/${params.id}/restore/`;
    }

    if (action === 'aggregate') {
        return `${endpoint}/aggregate`;
    }

    if (['search', 'autocomplete', 'suggest'].includes(action)) {
        return `${endpoint}/${action}/`;
    }

    if (endpoint.indexOf(':id') !== -1) {
        if (!(params && params.id)) {
            throw new Error(`${action} needs an id in params`);
        }

        return endpoint.replace(':id', params.id);
    }

    if (action === 'store' && params?.id) {
        return `${endpoint}/${params.id}/`;
    }

    return `${endpoint}/`;
};

const calculateGetParamCount = params => {
    const json = JSON.stringify(params);
    const noColon = json.replaceAll(':', '');
    return json.length - noColon.length;
};

const prepareParams = (params, action) => ({
    limit: action === 'autocomplete' ? 100 : undefined,
    ...params,
    with: combineWith(params.with, params.additional).join(',') || undefined,
    additional: undefined,
});

const createRestMethod =
    (api, name, endpoint, action, initialMethod) =>
    (params = {}, extra = {}, sequentialId = null) => {
        const isBulkParams = checkParamsBulk(params, true);
        const preppedParams = isBulkParams
            ? params.map(paramItem => prepareParams(paramItem, action))
            : prepareParams(params, action);
        const { id, ...restParams } = preppedParams;

        api.dispatch(pendingAction(name, action, preppedParams, extra));

        const overrideGetToPost = initialMethod === 'get' && calculateGetParamCount(restParams) > 8;
        const method = overrideGetToPost ? 'post' : initialMethod;

        const cancelToken = Sequentializer.push(name, sequentialId);
        const authUserToken = api.getState().auth.user?.auth_token;

        const axiosParams = {
            method,
            url: getEndpointUrl(endpoint, action, preppedParams),
            baseURL: process.env.REACT_APP_API_ROOT,
            params: method === 'get' ? restParams : {},
            data: method !== 'get' ? preppedParams : {},
            headers: {
                ...(api.echo ? { 'X-Socket-Id': api.echo.socketId() } : {}),
                ...(overrideGetToPost ? { 'X-HTTP-Method-Override': 'GET' } : {}),
                ...(authUserToken ? { 'X-Auth-Token': authUserToken } : {}),
            },
            paramsSerializer:
                method === 'get'
                    ? _params => {
                          return qs.stringify(_params, {
                              encodeValuesOnly: true,
                          });
                      }
                    : undefined,
            cancelToken,
        };

        if (params && Object.values(params).some(val => val instanceof File)) {
            const formData = new FormData();

            Object.keys(params).forEach(key => {
                formData.append(key, params[key]);
            });

            // See https://stackoverflow.com/questions/47676134/laravel-request-all-is-empty-using-multipart-form-data
            // See https://bugs.php.net/bug.php?id=55815
            if (method.toLowerCase() !== 'post') {
                axiosParams.method = 'post';
                formData.append('_method', method);
            }

            delete axiosParams.params;
            axiosParams.data = formData;
        }

        return axios(axiosParams)
            .then(({ data: { data, meta, ...extraData } }) => [
                data,
                { ...meta, ...extra },
                extraData,
            ])
            .then(([data, meta, extraData]) => {
                if (action === 'destroy') {
                    if (data.id) {
                        return [data.id, { ...meta, archived: data }, extraData]; // object was soft deleted
                    }

                    if (Array.isArray(data) && Number.isInteger(data[0])) {
                        return [data, meta, extraData]; // prepare bulk delete response
                    }

                    return [parseInt(data, 10), meta, extraData]; // destroy returns id as string for whatever reason
                }
                return [data, meta, extraData];
            })
            .then(([data, meta, extraData]) => {
                const isBulkRequest =
                    ['store', 'update', 'destroy'].includes(action) &&
                    Array.isArray(data) &&
                    data.length > 1;

                const metaParam = {
                    ...meta,
                    ...extra,
                    ...extraData,
                };

                if (isBulkRequest) {
                    api.dispatch(
                        bulkFulfilledAction(name, `${action}Bulk`, params, data, metaParam)
                    );
                }

                if (!isBulkRequest || (isBulkRequest && action !== 'destroy')) {
                    const fulfilledAction = isBulkRequest ? 'index' : action;
                    const payload =
                        ['show', 'store', 'update'].includes(action) &&
                        Array.isArray(data) &&
                        data.length === 1
                            ? data[0]
                            : data;

                    api.dispatch(
                        bulkFulfilledAction(name, fulfilledAction, params, payload, metaParam)
                    );
                }

                return { data, meta, extraData };
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    // eslint-disable-next-line no-param-reassign
                    error.canceled = true;
                    throw error;
                }

                // logger.error(error);

                api.dispatch(
                    apiError({
                        status: (error.response && error.response.status) || null,
                        message: error.message,
                        key: name,
                        response: error.response?.data,
                        action,
                        params,
                        ...extra,
                    })
                );

                api.dispatch(errorAction(name, action, params, error.message, extra));
                throw error;
            });
    };

export const attachRestResources = (api, resources) => {
    resources.forEach(resource => {
        // eslint-disable-next-line no-param-reassign
        api[resource.name] = {
            config: resource,
        };

        Object.keys(methods).forEach(action => {
            // eslint-disable-next-line no-param-reassign
            api[resource.name][action] = createRestMethod(
                api,
                resource.name,
                resource.endpoint,
                action,
                methods[action]
            );
        });

        (resource.extra || []).forEach(({ action, endpoint, method }) => {
            // eslint-disable-next-line no-param-reassign
            api[resource.name][action] = createRestMethod(
                api,
                resource.name,
                endpoint,
                action,
                method
            );
        });
    });
};
