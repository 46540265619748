import React, { useCallback, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { IdPropType } from '../../../modules/proptypes';
import JournalTable from '../../journal/JournalTable';
import { useSelector } from 'react-redux';
import { selectEmployeeById } from '../../../modules/employees/employeesSlice';

const EmployeeJournal = ({ employeeId }) => {
    const employee = useSelector(state => selectEmployeeById(state, employeeId));
    const referenceId = useMemo(
        () => ({ key: 'user_id', value: employee?.user_id }),
        [employee?.user_id]
    );

    const handleSearch = useCallback(filters => {}, []);

    return (
        <JournalTable
            listId={`journals.employees.${employeeId}`}
            referenceId={referenceId}
            onSearch={handleSearch}
        />
    );
};

EmployeeJournal.propTypes = {
    employeeId: IdPropType.isRequired,
};

export default EmployeeJournal;
