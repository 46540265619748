import Persistor from '../persistor/persistor';
import { useEffect, useMemo, useRef, useState } from 'react';
import { exportDate, importDate } from '../datetime/utils';
import { RESOURCES_MODES } from '../../components/calendar/CalendarResourcesPicker';
import {
    calcDateRange,
    calcMissingDateRange,
    CALENDAR_RESOURCE_MODELS,
    CALENDAR_VIEWS,
    FLAT_UNPLANNED,
    formatEmployeeForCalendar,
    formatFlatResourceKey,
    formatResourceForCalendar,
    getAppointmentTypeIdsByOverlapType,
} from './utils';
import { useCallbackFunc } from '../hooks';
import { useResourceList } from '../lists/hooks';
import { APPOINTMENT_RESOURCE, EMPLOYEE_RESOURCE, RESOURCE_RESOURCE } from '../api/resources';
import { useDispatch, useSelector } from 'react-redux';
import {
    convertAppointmentsByRowToDailyMax,
    formatAppointmentImport,
    isAppointmentInRange,
} from '../appointments/utils';
import debounce from 'lodash/debounce';
import {
    indexAppointmentTypes,
    selectAllAppointmentTypes,
} from '../appointmentTypes/appointmentTypeSlice';
import { usePriorities } from '../priority/prioritySlice';
import { selectAllEmployeeIds, selectAllEmployees } from '../employees/employeesSlice';
import { selectAllResourceIds, selectAllResources } from '../resources/resourcesSlice';
import { areIntervalsOverlapping } from 'date-fns';

export const useCalendarAppointments = ({
    calendarId,
    defaultEntityId,
    defaultEntityResource,
    extraParams = null,
    overrideFetchKey = null,
    onCheckParams,
    onFormatAppointment,
    onSplitAppointment,
    onAssignAppointmentByRow,
}) => {
    const viewPersistorKey = `${calendarId}.view`;
    const initialView = Persistor.get(viewPersistorKey, CALENDAR_VIEWS.WEEK);
    const dispatch = useDispatch();
    const [currentView, setCurrentView] = useState(initialView);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedResources, setSelectedResources] = useState(null);
    const [hideLoading, setHideLoading] = useState(false);
    const knownRange = useRef(null);
    const lastFetchParams = useRef(null);
    usePriorities();

    const isDefaultResourcePickerIntegrated = useMemo(
        () => Object.keys(RESOURCES_MODES).includes(defaultEntityResource),
        [defaultEntityResource, RESOURCES_MODES]
    );

    const resourceParams = useMemo(() => {
        if (isDefaultResourcePickerIntegrated) {
            return (
                selectedResources || {
                    id: defaultEntityId,
                    type: CALENDAR_RESOURCE_MODELS[defaultEntityResource],
                }
            );
        }

        return selectedResources || [];
    }, [
        selectedResources,
        defaultEntityId,
        defaultEntityResource,
        isDefaultResourcePickerIntegrated,
        CALENDAR_RESOURCE_MODELS,
    ]);

    //ToDo: Only needed for "unplanned" -> implement as extra param (incl. stableCheckCriteria)
    const handleExtraParams = (params = {}) => {
        if (extraParams) {
            if (typeof extraParams === 'function') {
                return extraParams(params);
            }

            if (typeof extraParams === 'object') {
                return {
                    ...params,
                    ...extraParams,
                };
            }
        }

        return params;
    };

    const createParams = useMemo(
        () =>
            handleExtraParams({
                resources: {
                    default: [defaultEntityId],
                },
                resource: resourceParams,
            }),
        [resourceParams, overrideFetchKey, defaultEntityId, defaultEntityResource]
    );

    const fetchParams = useMemo(
        () =>
            handleExtraParams(
                selectedResources
                    ? {
                          limit: 500,
                          resource: resourceParams,
                          ...(overrideFetchKey ? { [overrideFetchKey]: defaultEntityId } : {}),
                      }
                    : null
            ),
        [resourceParams, selectedResources, defaultEntityId]
    );

    const { selectedEmployeeIds, selectedResourceIds } = useMemo(() => {
        const empty = { selectedEmployeeIds: [], selectedResourceIds: [] };

        if (!Array.isArray(selectedResources) || selectedResources.length === 0) {
            return empty;
        }

        return selectedResources.reduce((carry, { id, type }) => {
            if (!id || !type) {
                return carry;
            }

            return {
                selectedEmployeeIds:
                    type === CALENDAR_RESOURCE_MODELS[EMPLOYEE_RESOURCE]
                        ? [...carry.selectedEmployeeIds, id]
                        : carry.selectedEmployeeIds,
                selectedResourceIds:
                    type === CALENDAR_RESOURCE_MODELS[RESOURCE_RESOURCE]
                        ? [...carry.selectedResourceIds, id]
                        : carry.selectedResourceIds,
            };
        }, empty);
    }, [selectedResources]);

    const handleCheckResourceMatches = resourceMatches => {
        if (Array.isArray(resourceParams)) {
            return !!resourceParams.find(({ id, type }) => {
                return (
                    type in resourceMatches &&
                    Array.isArray(resourceMatches[type]) &&
                    resourceMatches[type].includes(id)
                );
            });
        }
        if (resourceParams && resourceParams.id) {
            return (
                resourceParams.type in resourceMatches &&
                resourceMatches[resourceParams.type].includes(resourceParams.id)
            );
        }
        return false;
    };

    const stableCheckCriteria = useCallbackFunc(item => {
        const formatted = formatAppointmentImport(item);
        const { resourceMatches } = formatted;
        const { resource: resourceMatchRequests } = fetchParams;

        const checks = [
            ...(resourceMatches && resourceParams.length !== 0
                ? [
                      handleCheckResourceMatches(resourceMatches) ||
                          (resourceMatchRequests.includes(FLAT_UNPLANNED.key) &&
                              !formatted?.plannedAt),
                  ]
                : []),
            ...(onCheckParams ? onCheckParams(formatted) : []),
        ];
        return checks.reduce((carry, check) => (!carry ? false : check), true);
    });

    const stableCompareCriteria = useCallbackFunc(() => 1);

    const { fullSelector, loading, initialized, handleFetch, handleClear } = useResourceList({
        index: true,
        listId: `${calendarId}.Calendar`,
        resource: APPOINTMENT_RESOURCE,
        continuous: true,
        criteria: {
            check: stableCheckCriteria,
            compare: stableCompareCriteria,
        },
    });

    const originalAppointments = useSelector(fullSelector);

    const viewRange = useMemo(
        () => calcDateRange(currentView, currentDate),
        [currentView, currentDate]
    );

    const { appointmentList, maxDailyAppointmentsPerRow } = useMemo(() => {
        const { appointmentList, viewedAppointmentsByRow } = Object.values(
            originalAppointments
        ).reduce(
            (carry, appointment) => {
                const formatted = formatAppointmentImport(appointment);
                const isVisible = isAppointmentInRange(formatted, viewRange);
                const extFormatted = onFormatAppointment
                    ? onFormatAppointment(formatted)
                    : formatted;
                const allAppointments = onSplitAppointment
                    ? onSplitAppointment(extFormatted, { selectedEmployeeIds, selectedResourceIds })
                    : [extFormatted];

                return {
                    appointmentList: [...carry.appointmentList, ...allAppointments],
                    viewedAppointmentsByRow: isVisible
                        ? onAssignAppointmentByRow(carry.viewedAppointmentsByRow, extFormatted)
                        : carry.viewedAppointmentsByRow,
                };
            },
            { appointmentList: [], viewedAppointmentsByRow: {} }
        );

        return {
            appointmentList,
            maxDailyAppointmentsPerRow: convertAppointmentsByRowToDailyMax(
                viewedAppointmentsByRow,
                viewRange
            ),
        };
    }, [
        originalAppointments,
        selectedEmployeeIds,
        selectedResourceIds,
        viewRange,
        onAssignAppointmentByRow,
    ]);

    const handleSearchDebounced = useMemo(
        () =>
            debounce(
                (params, nextKnownRange) =>
                    handleFetch(params).then(data => {
                        knownRange.current = nextKnownRange;
                        return data;
                    }),
                128
            ),
        [handleFetch]
    );

    const setView = viewMode => {
        setCurrentView(viewMode);
        Persistor.set(viewPersistorKey, viewMode);
    };

    const applyHideLoading = useCallbackFunc((nextHideLoading, dateRangeReset) => {
        if (nextHideLoading !== hideLoading) {
            setHideLoading(nextHideLoading);
        }

        if (dateRangeReset && !nextHideLoading) {
            handleClear();
        }
    });

    useEffect(() => {
        const fetchParamsJson = JSON.stringify(fetchParams);
        const fetchParamsChanged = fetchParamsJson !== lastFetchParams.current;

        const currentKnownRange = knownRange.current;
        const viewedRange = calcDateRange(currentView, currentDate);
        const inclosedRange = calcDateRange(currentView, currentDate, 1);

        const viewWithinKnown =
            currentKnownRange?.start instanceof Date && currentKnownRange?.end instanceof Date
                ? areIntervalsOverlapping(viewedRange, currentKnownRange)
                : false;

        const {
            targetRange,
            knownRange: nextKnownRange,
            changed: rangeChanged,
            reset: dateRangeReset,
        } = calcMissingDateRange(inclosedRange, currentKnownRange, fetchParamsChanged);

        applyHideLoading(!fetchParamsChanged && viewWithinKnown, dateRangeReset);

        if (fetchParamsChanged) {
            lastFetchParams.current = fetchParamsJson;
        }

        if ((fetchParamsChanged || rangeChanged) && Array.isArray(fetchParams?.resource)) {
            const dateParams = {
                starts_at: { to: exportDate(targetRange.end) },
                ends_at: { from: exportDate(targetRange.start) },
            };

            handleSearchDebounced(
                {
                    ...dateParams,
                    ...fetchParams,
                    with_shallow: 'resources',
                    limit: 500,
                },
                nextKnownRange
            );
        }
    }, [currentDate, currentView, fetchParams, handleSearchDebounced]);

    useEffect(() => {
        dispatch(indexAppointmentTypes({ with: ['appointment_permissions'] }));
    }, []);

    return useMemo(
        () => ({
            appointmentList,
            originalAppointments,
            maxDailyAppointmentsPerRow,
            loading: !hideLoading && loading,
            initialized,
            currentDate,
            currentView,
            fetchParams,
            resourceParams,
            createParams,
            selectedEmployeeIds,
            selectedResourceIds,
            setCurrentDate,
            setSelectedResources,
            setView,
        }),
        [
            appointmentList,
            originalAppointments,
            maxDailyAppointmentsPerRow,
            hideLoading,
            loading,
            initialized,
            currentDate,
            currentView,
            fetchParams,
            resourceParams,
            createParams,
            selectedEmployeeIds,
            selectedResourceIds,
            setCurrentDate,
            setSelectedResources,
            setView,
        ]
    );
};

const RESOURCE_INSTANCE_CONFIG = {
    [RESOURCE_RESOURCE]: {
        format: rawResource => {
            const resource = formatResourceForCalendar(rawResource);

            return {
                ...resource,
                id: formatFlatResourceKey(resource.id, RESOURCE_RESOURCE).key,
            };
        },
    },
    [EMPLOYEE_RESOURCE]: {
        format: rawEmployee => {
            const employee = formatEmployeeForCalendar(rawEmployee);

            return {
                ...employee,
                id: formatFlatResourceKey(employee.id, EMPLOYEE_RESOURCE).key,
            };
        },
    },
};

export const useCalendarResourceBasicInstances = (type = EMPLOYEE_RESOURCE) => {
    const { format } = useMemo(() => RESOURCE_INSTANCE_CONFIG[type], [type]);
    const fullSelector = type === EMPLOYEE_RESOURCE ? selectAllEmployees : selectAllResources;
    const idsSelector = type === EMPLOYEE_RESOURCE ? selectAllEmployeeIds : selectAllResourceIds;

    const list = useSelector(fullSelector);
    const dataIds = useSelector(idsSelector);

    const instances = useMemo(() => {
        return list.map(resource => ({
            type,
            ...format(resource),
        }));
    }, [type, list, format]);

    return useMemo(
        () => ({
            instances,
            dataIds,
            fullSelector,
        }),
        [instances, dataIds, fullSelector]
    );
};

export const useDeniedPeriods = (appointments = []) => {
    const appointmentTypes = useSelector(selectAllAppointmentTypes);

    const { denyIds, warnIds } = useMemo(
        () => getAppointmentTypeIdsByOverlapType(appointmentTypes),
        [appointmentTypes]
    );

    const deniedPeriods = useMemo(
        () =>
            appointments.reduce((carry, appointment) => {
                const appointmentId = appointment.originalId;
                const apTypeId = appointment.appointmentTypeId;
                const start = appointment.startDate;
                const end = appointment.endDate;
                const allDay = appointment.allDay;
                const baseResources = appointment.baseResources;

                if (apTypeId && denyIds.includes(apTypeId)) {
                    return [
                        ...carry,
                        {
                            appointmentId,
                            start: importDate(start),
                            end: importDate(end),
                            allDay,
                            resources: baseResources || [],
                        },
                    ];
                }

                return carry;
            }, []),
        [appointments, denyIds]
    );

    return {
        denyIds,
        warnIds,
        deniedPeriods,
    };
};
