// import './wdyr';
import React from 'react';
import { createRoot } from 'react-dom/client';
import initI18n from './i18n/i18n';
import axios from 'axios';
import App from './App';
import * as serviceWorker from './serviceWorker';

initI18n();

// see at https://bobbyhadz.com/blog/react-reacdom-render-no-longer-supported-in-react-18
const root = createRoot(document.getElementById('root'));
const renderApp = () => root.render(<App />);

if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./App', renderApp);
}

// Sanctum sagt, wir brauchen das?
axios.defaults.withCredentials = true;

renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
