import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { TableSortingPropType } from '../table/proptypes';
import { makeStyles, useTheme } from '@mui/styles';
import {
    useOrderedFullSelect,
    useResourceList,
    useResourceListNestedSelects,
} from '../../modules/lists/hooks';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { MRT_Localization_DE } from 'material-react-table/locales/de';
import { useResourceTableColumns } from '../../modules/resourceTable/hooks';
import { getOrderByFromSorting, getSortingFromOrderBy } from '../../modules/resourceTable/utils';
import { Grid } from '@mui/material';
import Persistor from '../../modules/persistor/persistor';
import ResourceTableExport from './ResourceTableExport';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import ResourceTableForm from './ResourceTableForm';
import ResourceTableRowActions from './ResourceTableRowActions';
import ResourceTableSelectionActions from './ResourceTableSelectionActions';
import { useSelector } from 'react-redux';
import { selectListParams } from '../../modules/lists/selectors';

const TABLE_PERSISTOR_OPTIONS = {
    SORT: 'sorting',
    DENSITY: 'density',
    COLUMN_VISIBLE: 'columnVisibility',
    COLUMN_ORDER: 'columnOrder',
    PAGE_SIZE: 'pageSize',
};

const getPersistorKey = (persistorId, persistorOption) => `table.${persistorId}.${persistorOption}`;

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },

    createButton: {
        display: 'flex',
        marginLeft: 'auto',
    },

    bottomToolbar: {},
}));

/**
 * Combined material-react-table with our useResourceList hook and further features
 *
 * @param resource
 * @param primaryListId
 * @param genericListId
 * @param label
 * @param rawColumns
 * Array of column definitions
 * Inherits 'mrt' column options: https://www.material-react-table.com/docs/api/column-options
 * Enhanced with further custom props
 * Most relevant props:
 * {
        //ToDo: further clarification
        accessorKey: general key tending to represent api model field key format // REQUIRED or 'id'
        id: general key tending to represent the internal frontend field format // REQUIRED or 'accessorKey'
        valueKey: key differ for internal sorting, aggregation, grouping etc.
        apiKey: key differ for api field sorting etc.
        labelKey: key differ used for automatic translation
        type: one of COLUMN_TYPES for quick default cell(view/edit/export/...) types
        convertString: function to format string in export or when not replaced by Cell component(might come from type)
        formatValue: function to refine final value resulted from type or convertString
        readOnly: disable edit even if Edit component is defined(might come from type)
        aggregationConfig: see /modules/aggregation/hooks.js
        (differ = different from accessorKey/id)
 * }
 * @param nestedSelectors
 * @param initialPageSize
 * @param initialFetchParams
 * @param initialSorting
 * @param initialSearch
 * @param initialDensity
 * @param initialColumnVisibility
 * @param contexts
 * @param autoload
 * @param index
 * @param withKeys
 * @param updateWatch
 * @param criteria
 * @param topToolbarCustomActions
 * @param renderRowMenuItems
 * @param renderSelectionOptions
 * @param renderExpandDetail
 * @param hidePageSizePicker
 * @param inlineEdit
 * @param fullScreen
 * @param exportCsv
 * @param rowSelect
 * @param stickyColumnActions
 * @param aggregationFooterFields
 * @param onOpen
 * @param onEdit
 * @param onDelete
 * @param onSubmit
 * @param onSelectedEdit
 * @param onSelectedDelete
 * @param onOrderDrop
 * @param other
 * @return {JSX.Element}
 */

const ResourceTable = ({
    resource,
    listId: primaryListId,
    genericListId,
    persistorId: overridePersistorId,
    label,
    columns: rawColumns,
    nestedSelectors,
    pageSize,

    fetchParams: initialFetchParams,
    initialSorting,
    initialSearch,
    initialDensity,
    initialColumnVisibility,

    contexts,
    autoload,
    index,
    with: withKeys,
    updateWatch,
    criteria,

    topToolbarCustomActions,
    renderRowMenuItems,
    renderSelectionOptions,
    renderExpandDetail,

    inlineEdit,

    hidePageSizePicker,
    fullScreen,
    exportCsv,
    rowSelect,
    stickyColumnActions,

    aggregationFooterFields,

    onOpen,
    onEdit,
    onDelete,
    onSubmit,
    onSelectedEdit,
    onSelectedDelete,
    onOrderDrop,
    onListChange,

    ...other
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const lastOrderBy = useRef(null);
    const lastList = useRef(null);

    const listId = useMemo(
        () => primaryListId || genericListId || resource,
        [primaryListId, genericListId, resource]
    );
    const persistorId = useMemo(() => overridePersistorId || listId, [overridePersistorId, listId]);

    const [density, setDensity] = useState(
        Persistor.get(getPersistorKey(persistorId, TABLE_PERSISTOR_OPTIONS.DENSITY), initialDensity)
    );
    const [columnVisibility, setColumnVisibility] = useState(
        Persistor.get(
            getPersistorKey(persistorId, TABLE_PERSISTOR_OPTIONS.COLUMN_VISIBLE),
            initialColumnVisibility
        )
    );
    const [columnOrder, setColumnOrder] = useState(
        Persistor.get(getPersistorKey(persistorId, TABLE_PERSISTOR_OPTIONS.COLUMN_ORDER), [])
    );
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: Persistor.get(
            getPersistorKey(persistorId, TABLE_PERSISTOR_OPTIONS.PAGE_SIZE),
            pageSize || 25
        ),
    });
    const initialSort = useMemo(
        () =>
            Persistor.get(
                getPersistorKey(persistorId, TABLE_PERSISTOR_OPTIONS.SORT),
                initialSorting
            ),
        [persistorId, initialSorting]
    );

    const [editingRow, setEditingRow] = useState(null);
    const isEdit = useMemo(
        () => !!(onEdit || (inlineEdit && onSubmit)),
        [inlineEdit, onEdit, onSubmit]
    );

    const fetchParams = useMemo(() => {
        return {
            ...(initialFetchParams || {}),
            limit: pagination.pageSize,
        };
    }, [initialFetchParams, pagination.pageSize]);

    const {
        dataIds,
        fullSelector,
        page,
        itemsTotal: rowCount,
        orderBy,
        loading,
        initialized,
        handlePage,
        handleOrderBy,
    } = useResourceList({
        listId,
        resource,
        fetchParams,
        initialOrderBy: onOrderDrop
            ? [['order', 'asc']]
            : getOrderByFromSorting(rawColumns, initialSort),
        initialSearch,
        contexts,
        autoload,
        index,
        with: withKeys,
        updateWatch,
        criteria,
    });

    const listParams = useSelector(state => selectListParams(state, listId));
    const list = useOrderedFullSelect(dataIds, fullSelector);
    const data = useResourceListNestedSelects({ list, nestedSelectors });

    const columns = useResourceTableColumns({
        rawColumns,
        listId: genericListId || listId,
        isEdit: inlineEdit && onSubmit,
        aggregationFooterFields,
    });

    const [sorting, setSorting] = useState(getSortingFromOrderBy(columns, orderBy));

    const handleReset = table => {
        table.setEditingRow(null);
        setEditingRow(null);
        table.resetExpanded();
        table.resetRowSelection();
        table.resetRowPinning();
        table.resetGrouping();
    };

    const handleSort = updater => {
        const newSorting = updater(sorting);

        const oldComparable = sorting.map(item => Object.values(item).join()).join();
        const newComparable = newSorting.map(item => Object.values(item).join()).join();

        if (oldComparable !== newComparable) {
            Persistor.set(getPersistorKey(persistorId, TABLE_PERSISTOR_OPTIONS.SORT), newSorting);

            const newOrderBy = getOrderByFromSorting(columns, newSorting);
            handleOrderBy(newOrderBy);
        }
    };

    const handlePagination = updater => {
        setPagination(pagination => {
            const nextPagination = updater(pagination);

            if (pagination.pageSize !== nextPagination.pageSize) {
                Persistor.set(
                    getPersistorKey(persistorId, TABLE_PERSISTOR_OPTIONS.PAGE_SIZE),
                    nextPagination.pageSize
                );
            }

            return nextPagination;
        });
    };

    const handleDensity = updaterOrValue => {
        const newValue =
            typeof updaterOrValue === 'function' ? updaterOrValue(density) : updaterOrValue;
        setDensity(newValue);
        Persistor.set(getPersistorKey(persistorId, TABLE_PERSISTOR_OPTIONS.DENSITY), newValue);
    };

    const handleColumnVisibility = updaterOrValue => {
        const newValue =
            typeof updaterOrValue === 'function'
                ? updaterOrValue(columnVisibility)
                : updaterOrValue;
        setColumnVisibility(newValue);
        Persistor.set(
            getPersistorKey(persistorId, TABLE_PERSISTOR_OPTIONS.COLUMN_VISIBLE),
            newValue
        );
    };

    const handleColumnOrder = updaterOrValue => {
        const newValue =
            typeof updaterOrValue === 'function' ? updaterOrValue(columnOrder) : updaterOrValue;
        setColumnOrder(newValue);
        Persistor.set(getPersistorKey(persistorId, TABLE_PERSISTOR_OPTIONS.COLUMN_ORDER), newValue);
    };

    const handleRowClick = ({ row, table }) => {
        if (renderExpandDetail) {
            table.setExpanded({ [row.id]: !row.getIsExpanded() });
        } else if (onOpen) {
            onOpen(row.original.id);
        }
    };

    const handleEdit = (row, table, event) => {
        if (inlineEdit) {
            setEditingRow(row.original);
            table.setEditingRow(row);
        }

        if (onEdit) {
            onEdit(row.original.id, row.original, event);
        }
    };

    const handleEditCancel = () => {
        setEditingRow(null);
    };

    const handleSubmit = (values, table) => {
        return onSubmit(values).then(() => {
            setEditingRow(null);
            if (typeof table === 'object' && typeof table.setEditingRow === 'function') {
                table.setEditingRow(null);
            }
        });
    };

    const generateRowProps = ({ row, table }) => {
        return {
            onClick: () => {
                handleRowClick({ row, table });
            },
            ...(renderExpandDetail
                ? {
                      ...(row.getIsExpanded()
                          ? {
                                sx: {
                                    backgroundColor: '#EEE',
                                },
                            }
                          : {}),
                  }
                : {}),
        };
    };

    const renderTopToolbarCustomActions = ({ table }) => (
        <>
            {!label && !topToolbarCustomActions && !exportCsv ? <Grid /> : null}
            {label && !topToolbarCustomActions ? (
                <Grid container alignItems="center" height={40}>
                    <Grid item>
                        <Typography variant="h1" color="primary">
                            {label}
                        </Typography>
                    </Grid>
                </Grid>
            ) : null}
            {typeof topToolbarCustomActions === 'function'
                ? topToolbarCustomActions(table, loading)
                : topToolbarCustomActions}
            {exportCsv ? (
                <ResourceTableExport
                    columns={columns}
                    rows={data}
                    label={label || listId}
                    disabled={loading}
                />
            ) : null}
        </>
    );

    const renderSelectionActions = ({ table, groupedAlert, selectedAlert }) => (
        <ResourceTableSelectionActions
            table={table}
            grouped={groupedAlert}
            selected={selectedAlert}
            resource={resource}
            onEdit={onSelectedEdit}
            onDelete={onSelectedDelete}
            onReset={handleReset}
            renderSelectionOptions={renderSelectionOptions}
        />
    );

    const renderInlineActions = ({ row, table, ...tableParams }) => (
        <ResourceTableRowActions
            resource={resource}
            row={row}
            table={table}
            tableParams={tableParams}
            onOpen={onOpen}
            onEdit={isEdit ? handleEdit : null}
            onDelete={onDelete}
            onCancel={isEdit ? handleEditCancel : null}
            renderRowMenuItems={
                renderRowMenuItems ? (...props) => renderRowMenuItems(...props, handleReset) : null
            }
        />
    );

    useEffect(() => {
        if (!loading && initialized) {
            const newPage = pagination.pageIndex + 1;

            if (
                (page && newPage !== page) ||
                (listParams?.limit && pagination.pageSize !== listParams.limit)
            ) {
                handlePage(null, newPage);
            }
        }
    }, [loading, initialized, page, pagination, listParams]);

    useEffect(() => {
        const nextOrderBy = orderBy.join();

        if (lastOrderBy.current !== nextOrderBy) {
            lastOrderBy.current = nextOrderBy;
            setSorting(getSortingFromOrderBy(columns, orderBy));
        }
    }, [columns, orderBy, setSorting]);

    useEffect(() => {
        if (typeof onListChange === 'function') {
            onListChange(data);
        }
    }, [data, onListChange]);

    const table = useMaterialReactTable({
        columns,
        data,
        rowCount,
        state: {
            isLoading: loading || !initialized,
            pagination: {
                pageIndex: page - 1,
                pageSize: pagination.pageSize,
            },
            sorting,
            density,
            columnVisibility,
            columnOrder,
            columnPinning: {
                left: stickyColumnActions ? ['mrt-row-select'] : [],
                right: stickyColumnActions ? ['mrt-row-actions'] : [],
            },
        },
        localization: {
            ...MRT_Localization_DE, //ToDo: Dynamic import i18n
            showAll: t('components.ResourceTable.showAll'),
            hideAll: t('components.ResourceTable.hideAll'),
            resetOrder: t('components.ResourceTable.resetOrder'),
        },

        paginationDisplayMode: 'pages',
        manualPagination: true,
        positionExpandColumn: 'first',
        positionActionsColumn: 'last',
        positionToolbarAlertBanner: 'bottom',
        editDisplayMode: inlineEdit ? 'row' : null,
        rowPinningDisplayMode: 'select-sticky',

        muiTableBodyRowProps: generateRowProps,
        muiTablePaperProps: { style: { height: '100%', backgroundColor: 'white' } },
        muiTableContainerProps: { style: { height: 'calc(100% - 108px)' } },
        muiTableFooterCellProps: {
            style: {
                backgroundColor: theme.palette.background.primary,
                color: theme.palette.text.primary,
            },
        },
        muiPaginationProps: {
            rowsPerPageOptions: [10, 25, 50, 100, 250],
            showRowsPerPage: !hidePageSizePicker,
            className: classes.bottomToolbar,
        },
        muiRowDragHandleProps: ({ table }) => ({
            onDragEnd: () => {
                const { draggingRow, hoveredRow } = table.getState();
                const id = draggingRow?.original?.id;
                const lastOrder = draggingRow?.original?.order;
                const order = hoveredRow?.original?.order;

                if (onOrderDrop && id && order && lastOrder !== order) {
                    onOrderDrop({
                        id,
                        order,
                    });
                }
            },
        }),
        displayColumnDefOptions: {
            'mrt-row-drag': { header: '' },
            'mrt-row-actions': { header: '', Cell: renderInlineActions },
        },

        enableFilters: false,
        enableGrouping: false,
        enableStickyHeader: true,
        enableStickyFooter: true,
        enableExpandAll: true,
        enableFullScreenToggle: fullScreen,
        enableEditing: inlineEdit,
        enableColumnDragging: true,
        enableColumnOrdering: !onOrderDrop,
        enableColumnActions: false,
        enableRowSelection: rowSelect,
        enableRowPinning: rowSelect,
        enableRowActions: !!(onOpen || isEdit || onDelete || renderRowMenuItems),
        enableRowOrdering: !!onOrderDrop,
        enableMultiRowSelection: rowSelect,
        enableRowVirtualization: Array.isArray(data) && data.length > 50,

        onPaginationChange: handlePagination,
        onSortingChange: handleSort,
        onDensityChange: handleDensity,
        onColumnVisibilityChange: handleColumnVisibility,
        onColumnOrderChange: handleColumnOrder,

        renderTopToolbarCustomActions,
        renderDetailPanel: renderExpandDetail,
        renderToolbarAlertBannerContent: renderSelectionActions,

        ...other,
    });

    useEffect(() => {
        const currentList = [...dataIds].sort().join();

        if (lastList.current !== currentList) {
            lastList.current = currentList;
            handleReset(table);
        }
    }, [dataIds, table, handleReset]);

    return inlineEdit ? (
        <ResourceTableForm
            editingRow={editingRow}
            resource={resource}
            onSubmit={values => handleSubmit(values, table)}
            {...(typeof inlineEdit === 'object' ? inlineEdit : {})}
        >
            <MaterialReactTable table={table} />
        </ResourceTableForm>
    ) : (
        <MaterialReactTable table={table} />
    );
};

ResourceTable.propTypes = {
    resource: PropTypes.string.isRequired,
    listId: PropTypes.string,
    genericListId: PropTypes.string,
    persistorId: PropTypes.string,
    label: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    nestedSelectors: PropTypes.arrayOf(
        PropTypes.shape({ model: PropTypes.string.isRequired, selector: PropTypes.func.isRequired })
    ),
    pageSize: PropTypes.number,

    initialSorting: PropTypes.arrayOf(TableSortingPropType),
    initialSearch: PropTypes.shape({}),
    initialDensity: PropTypes.oneOf(['comfortable', 'compact', 'spacious']),
    initialColumnVisibility: PropTypes.shape({}),

    fetchParams: PropTypes.shape({}),
    contexts: PropTypes.arrayOf(PropTypes.string),
    autoload: PropTypes.bool,
    index: PropTypes.bool,
    with: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.arrayOf(PropTypes.string)]),
    updateWatch: PropTypes.number,
    criteria: PropTypes.shape({
        check: PropTypes.func.isRequired,
        compare: PropTypes.func.isRequired,
        prepare: PropTypes.func,
    }),

    topToolbarCustomActions: PropTypes.node,
    renderRowMenuItems: PropTypes.node,
    renderSelectionOptions: PropTypes.node,
    renderExpandDetail: PropTypes.func,

    inlineEdit: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),

    hidePageSizePicker: PropTypes.bool,
    fullScreen: PropTypes.bool,
    exportCsv: PropTypes.bool,
    rowSelect: PropTypes.bool,
    stickyColumnActions: PropTypes.bool,
    aggregationFooterFields: PropTypes.shape({}),

    onOpen: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onSubmit: PropTypes.func,
    onSelectedEdit: PropTypes.func,
    onSelectedDelete: PropTypes.func,
    onOrderDrop: PropTypes.func,
    onListChange: PropTypes.func,
};

ResourceTable.defaultProps = {
    listId: null,
    nestedSelectors: [],
    genericListId: null,
    persistorId: null,
    label: null,
    pageSize: 25,

    initialSorting: undefined,
    initialSearch: undefined,
    initialColumnVisibility: {},
    initialDensity: 'compact',

    fetchParams: null,
    contexts: [],
    autoload: false,
    index: false,
    with: null,
    updateWatch: null,
    criteria: null,

    topToolbarCustomActions: null,
    renderRowMenuItems: null,
    renderSelectionOptions: null,
    renderExpandDetail: null,

    inlineEdit: false,

    hidePageSizePicker: false,
    fullScreen: true,
    exportCsv: false,
    rowSelect: false,
    stickyColumnActions: false,
    aggregationFooter: null,

    onOpen: null,
    onEdit: null,
    onDelete: null,
    onSubmit: null,
    onSelectedEdit: null,
    onSelectedDelete: null,
    onOrderDrop: null,
    onListChange: null,
};

export default memo(ResourceTable);
