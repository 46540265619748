import * as Yup from 'yup';

export const getJournalSchema = referenceId =>
    Yup.object().shape(
        {
            task_id: Yup.number().nullable(),
            task_type_id: Yup.number().nullable().required('required'),
            card_id:
                referenceId?.key === 'card_id'
                    ? Yup.number().nullable()
                    : Yup.number().nullable().required('required'),
            appointment_id: Yup.number().nullable(),
            user_id: Yup.array().of(Yup.number()).nullable(),
            id_invoice: Yup.string().nullable(),

            started_at: Yup.date().nullable().required('required'),
            started_has_time: Yup.bool().when('duration', {
                is: value => !value,
                then: schema => schema.oneOf([true, null], 'required'),
                otherwise: schema => schema.nullable(),
            }),

            duration: Yup.string().when('started_has_time', {
                is: value => !value,
                then: schema => schema.nullable().required('required'),
                otherwise: schema => schema.nullable(),
            }),
            duration_billable: Yup.string().nullable(),
            description: Yup.string().nullable(),
        },
        ['started_has_time', 'duration']
    );

export const journalMoveSchema = Yup.object().shape({
    card_id: Yup.number().nullable().required('required'),
    task_id: Yup.number().nullable(),
    appointment_id: Yup.number().nullable(),
});
